import { useState, useEffect, useMemo, useRef, useContext } from "react";
import { useNavigate } from "react-router";
import {
  Typography,
  Dialog,
  Spinner,
  Button,
  Avatar,
  Input,
  DialogHeader,
  DialogFooter,
  DialogBody,
} from "@material-tailwind/react";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { VncScreen } from "react-vnc";

import Layout from "../components/Layout";
import RegisterAgentModal from "../components/RegisterAgentModal";
import PlusButtonText from "../components/PlusButtonText";
import { AuthContext } from "../server/AuthContext";
import { listAgents } from "../api/Agents";
import { getAPIAddr } from "../api/Env";

export default function HomePage() {
  const ref = useRef();
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openRegister, setOpenRegister] = useState(false);
  const [runningAgents, setRunningAgents] = useState([]);

  const handleOpenRegister = () => setOpenRegister(!openRegister);
  const handleCloseRegister = () => setOpenRegister(false);

  function convertHttpsToWss(httpsUrl) {
    const url = new URL(httpsUrl);
    url.protocol = url.protocol.replace("https", "wss");
    return url.toString();
  }

  const handleAgentClick = (agentObj) => {
    navigate("/agents/" + agentObj.name);
  };

  useEffect(() => {
    if (token) {
      setLoading(true);
      listAgents(token)
        .then((data) => {
          if (data && Array.isArray(data)) {
            // Check if data is defined and is an array
            setAgents(data);
            setRunningAgents(
              data.filter((agent) => agent.status === "running")
            );
          } else {
            console.error("Received invalid data from listAgents:", data);
            setAgents([]);
            setRunningAgents([]);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Failed to fetch agents:", error);
          setLoading(false);
        });

      const intervalId = setInterval(() => {
        listAgents(token)
          .then((data) => {
            if (data && Array.isArray(data)) {
              setAgents(data);
              setRunningAgents(
                data.filter((agent) => agent.status === "running")
              );
            } else {
              console.error(
                "Received invalid data from listAgents on interval:",
                data
              );
            }
            setLoading(false);
          })
          .catch((error) => {
            console.error("Failed to fetch agents on interval:", error);
            setLoading(false);
          });
      }, 2000);

      return () => clearInterval(intervalId);
    }
  }, [token]);

  const showCreateAgentButtonBig = useMemo(() => {
    // Check if there are no tasks
    if (runningAgents && runningAgents.length > 0) {
      return false;
    }
    return true;
  }, [runningAgents]);

  if (loading) {
    return (
      <Layout>
        <div className="flex justify-center items-center mt-72">
          <Spinner className="h-24 w-24" />
        </div>
      </Layout>
    );
  }

  return (
    <>
      <Dialog open={openRegister} handler={handleOpenRegister}>
        <DialogHeader>
          <PlusCircleIcon className="h-5 w-5 mr-4" />
          Create an Agent
        </DialogHeader>
        <DialogBody>
          <RegisterAgentModal onClose={handleCloseRegister} />
          {/* <Typography className="text-2xl font-bold mb-4">Enter the URL of the agent</Typography>
                <Input className="" label="Agent URL" type="text"/> */}
        </DialogBody>
        <DialogFooter></DialogFooter>
      </Dialog>
      <Layout>
        <div className="flex flex-row mt-10">
          {showCreateAgentButtonBig ? (
            <div className="items-center justify-center flex flex-col w-full mt-24">
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                className="border border-black-400 rounded-xl mt-10 p-24"
              >
                <Typography
                  variant="h5"
                  className="font-tifax text-center justify-center"
                >
                  Create an agent to start surfing 🏄‍♂️
                </Typography>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.5 }}
                  className="flex flex-row items-center justify-center text-center w-full mt-10"
                >
                  <Button
                    size="lg"
                    className="flex flex-row w-full items-center justify-center gap-3"
                    onClick={handleOpenRegister}
                  >
                    <PlusCircleIcon className="w-5 h-5" />
                    Create Agent
                  </Button>
                </motion.div>
              </motion.div>
            </div>
          ) : (
            // <div className="ml-48">{/* <AgentsSidebar /> */}</div>
            <div className="flex flex-col ml-48">
              <div className="pl-16 flex flex-row gap-6 items-center">
                <Typography className="text-2xl font-bold">
                  Active Agents
                </Typography>
                <Typography className="text-2xl font-bold text-gray-700">
                  {runningAgents?.length}
                </Typography>
                {/* <div className="w-72">
                  <Input label="Search" />
                </div> */}
              </div>
              <div className="mx-auto flex flex-wrap p-16 gap-6">
                <motion.div
                  className="mt-24 mr-28 mb-8"
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                  whileHover={{ scale: 1.05 }}
                >
                  <PlusButtonText text="Create" onClick={handleOpenRegister} />
                </motion.div>
                {runningAgents &&
                  runningAgents.map((agentObj, key) => (
                    <motion.div
                      className="mb-8 flex flex-col cursor-pointer"
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.5 }}
                      onClick={(e) => handleAgentClick(agentObj)}
                    >
                      <div className="flex flex-row items-center mb-2 gap-2">
                        <Avatar src={agentObj.icon} className="w-5 h-5" />
                        <Typography variant="h5" className="font-tifax">
                          {agentObj.name}
                        </Typography>
                      </div>
                      <div className="shadow-xl">
                        <VncScreen
                          url={convertHttpsToWss(
                            getAPIAddr() +
                              "/ws/vnc/" +
                              agentObj.name +
                              "?token=" +
                              token
                          )}
                          scaleViewport
                          viewOnly={true}
                          background="#000000"
                          style={{ width: "350px", height: "280px" }}
                          rfbOptions={{
                            credentials: { password: "agentsea123" },
                            shared: true,
                          }}
                          ref={ref}
                          debug={true}
                          showDotCursor={true}
                          onConnect={(rfb) => {
                            console.log(
                              `CHANGE Connected to VNC server at timestamp:`,
                              Date.now()
                            );
                            console.log(rfb);
                          }}
                          onDisconnect={(rfb) => {
                            console.log(
                              `CHANGE Disconnected from VNC server at timestamp:`,
                              Date.now()
                            );
                            console.log(rfb);
                          }}
                          onSecurityFailure={(e) =>
                            console.log(
                              `CHANGE Security failure: ${e.detail} at timestamp:`,
                              Date.now()
                            )
                          }
                          //   loadingUI={<div>Connecting to VNC server...</div>}
                        />
                      </div>
                    </motion.div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
}
