import React, { useState, useContext } from "react";
import {
  Typography,
  Input,
  Button,
  Spinner,
  Select,
  Option,
  Textarea,
} from "@material-tailwind/react";
import { registerAgentRuntime } from "../api/Runtime";
import { XCircleIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import { AuthContext } from "../server/AuthContext";

export default function RegisterAgentRuntimeModal({ onClose }) {
  const { token } = useContext(AuthContext);
  console.log("got token from auth context: ", token);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [name, setName] = useState("");
  const [runtime, setRuntime] = useState("");
  const [gceCredentials, setGceCredentials] = useState("");
  const [awsAccessKey, setAwsAccessKey] = useState("");
  const [awsSecretKey, setAwsSecretKey] = useState("");
  const [namespace, setNamespace] = useState("");
  const [region, setRegion] = useState("");
  const [clusterName, setClusterName] = useState("");
  const [sharedWith, setSharedWith] = useState([]);
  const [currentShareWithEmail, setCurrentShareWithEmail] = useState("");

  const handleGceCredentialsChange = (e) => {
    setGceCredentials(e.target.value);
  };

  const handleAwsAccessKeyChange = (e) => {
    setAwsAccessKey(e.target.value);
  };

  const handleNamespaceChange = (e) => {
    setNamespace(e.target.value);
  };

  const handleClusterNameChange = (e) => {
    setClusterName(e.target.value);
  };

  const handleRegionChange = (e) => {
    setRegion(e.target.value);
  };

  const handleAwsSecretKeyChange = (e) => {
    setAwsSecretKey(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleAddSharedWithEmail = (e) => {
    if (e.key === "Enter" && currentShareWithEmail.trim() !== "") {
      e.preventDefault(); // Prevent form submission
      if (!sharedWith.includes(currentShareWithEmail.trim())) {
        setSharedWith([...sharedWith, currentShareWithEmail.trim()]);
        setCurrentShareWithEmail(""); // Reset the input field
      }
    }
  };

  const removeSharedWithEmail = (indexToRemove) => {
    setSharedWith(sharedWith.filter((_, index) => index !== indexToRemove));
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleSubmit = (e) => {
    e.preventDefault();
    // Example: fetch('your-backend-endpoint', { method: 'POST', body: JSON.stringify({ url: agentUrl }), ... });

    setLoading(true);

    var metadata = {};
    var credentials = {};
    if (runtime === "gke") {
      credentials["GOOGLE_APPLICATION_CREDENTIALS_JSON"] = gceCredentials;
      metadata["NAMESPACE"] = namespace;
      metadata["CLUSTER_NAME"] = clusterName;
      metadata["REGION"] = region;
    } else if (runtime === "eks") {
      credentials["AWS_ACCESS_KEY_ID"] = awsAccessKey;
      credentials["AWS_SECRET_ACCESS_KEY"] = awsSecretKey;
      metadata["NAMESPACE"] = namespace;
    }

    registerAgentRuntime(
      name,
      runtime,
      credentials,
      metadata,
      sharedWith,
      token
    )
      .then((response) => {
        // Handle the response here
        console.log("Agent runtime registered:", response);
        setLoading(false);
      })
      .then(() => {
        setDone(true);
        setLoading(false);
      })
      .then(() => sleep(1000))
      .then(() => onClose())
      .catch((error) => {
        // Handle any errors here
        console.error("Error registering agent:", error);
        setError(true);
        setLoading(false);
      });
  };

  return (
    <div className="">
      {loading && <Spinner className="h-12 w-12" />}
      {error && (
        <div className="flex flex-row">
          <XCircleIcon className="mr-2 w-12 h-12" />
          <Typography className="text-2xl font-bold mb-4">
            Error occurred while registering
          </Typography>
        </div>
      )}
      {done && (
        <div className="flex flex-row">
          <CheckCircleIcon className="mr-2 w-12 h-12" />
          <Typography className="text-2xl font-bold mb-4">
            Registration successful!
          </Typography>
        </div>
      )}

      {!loading && !done && !error && (
        <div className="">
          <form onSubmit={handleSubmit} className="gap-4">
            <div className="mb-4">
              <Input
                className=""
                label="Name of the provider"
                type="text"
                value={name}
                onChange={handleNameChange}
              />
            </div>
            <div className="mb-4">
              <Select
                value={runtime}
                onChange={(e) => setRuntime(e)}
                label="Select runtime"
                selected={(element) =>
                  element &&
                  React.cloneElement(element, {
                    disabled: true,
                    className:
                      "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
                  })
                }
              >
                <Option value="gke" className="flex items-center gap-2">
                  {" "}
                  <img
                    src="https://www.gend.co/hs-fs/hubfs/gcp-logo-cloud.png?width=730&name=gcp-logo-cloud.png"
                    alt="gke"
                    className="h-5 w-5 rounded-full object-cover"
                  />
                  GKE
                </Option>
              </Select>
            </div>
            {runtime === "gke" && (
              <>
                <div className="mb-4">
                  <Textarea
                    type="text"
                    label="GCE JSON Key"
                    value={gceCredentials}
                    onChange={handleGceCredentialsChange}
                  />
                </div>
                <div className="mb-4">
                  <Input
                    type="text"
                    label="Namespace"
                    className="mb-4"
                    value={namespace}
                    onChange={handleNamespaceChange}
                  />
                </div>
                <div className="mb-4">
                  <Input
                    type="text"
                    label="Cluster Name"
                    className="mb-4"
                    value={clusterName}
                    onChange={handleClusterNameChange}
                  />
                </div>
                <div className="mb-4">
                  <Input
                    type="region"
                    label="Region"
                    className="mb-4"
                    value={region}
                    onChange={handleRegionChange}
                  />
                </div>
              </>
            )}

            {runtime === "ec2" && (
              <>
                <div className="mb-4">
                  <Input
                    type="text"
                    label="AWS Access Key"
                    className="mb-4"
                    value={awsAccessKey}
                    onChange={handleAwsAccessKeyChange}
                  />
                </div>
                <div className="mb-4">
                  <Input
                    type="password" // Use password type to hide input
                    label="AWS Secret Key"
                    className="mb-4"
                    value={awsSecretKey}
                    onChange={handleAwsSecretKeyChange}
                  />
                </div>
              </>
            )}
            <div className="mb-4">
              <Input
                type="text"
                label="Emails to share with (press Enter to add)"
                value={currentShareWithEmail}
                onChange={(e) => setCurrentShareWithEmail(e.target.value)}
                onKeyDown={handleAddSharedWithEmail}
              />
              <div className="flex flex-wrap mt-2">
                {sharedWith.map((email, index) => (
                  <div
                    key={index}
                    className="flex items-center mr-2 mb-2 bg-gray-200 p-2 rounded"
                  >
                    {email}
                    <XCircleIcon
                      className="ml-2 h-5 w-5 cursor-pointer"
                      onClick={() => removeSharedWithEmail(index)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <Button
              className="float-right mt-2 mb-4 w-full"
              variant="outlined"
              rounded
              onClick={handleSubmit}
            >
              Create
            </Button>
          </form>
        </div>
      )}
    </div>
  );
}
