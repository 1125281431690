import { Typography } from "@material-tailwind/react";
import { CopyBlock, nord } from "react-code-blocks";

export function H1({ children }) {
  return (
    <Typography variant="h2" className="mb-4">
      {children}
    </Typography>
  );
}

export function H2({ children }) {
  return (
    <Typography variant="h4" className="mb-4 mt-2">
      {children}
    </Typography>
  );
}

export function H3({ children }) {
  return (
    <Typography variant="h5" className="mb-4">
      {children}
    </Typography>
  );
}

export function H4({ children }) {
  return (
    <Typography variant="h6" className="mb-3">
      {children}
    </Typography>
  );
}

export function P({ children }) {
  return <p className="mb-4 flex flex-wrap">{children}</p>;
}

export function CodeSnippet({ text, language }) {
  return (
    <div className="mb-4">
      <CopyBlock
        text={text}
        language={language}
        showLineNumbers={false}
        theme={nord}
        wrapLines
      />
    </div>
  );
}

export function CodeFile({ text, language, fileName }) {
  return (
    <div className="mb-4">
      <div className="bg-gray-100 text-sm w-fit px-2 py-1">{fileName}</div>
      <CopyBlock
        text={text}
        language={language}
        showLineNumbers={false}
        theme={nord}
        wrapLines
      />
    </div>
  );
}
