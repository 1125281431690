import React from "react";
import { Typography } from "@material-tailwind/react";
import DocsLayout from "../../components/DocsLayout";

export default function AppOverviewPage() {
    return (
        <DocsLayout>
            <div className="flex flex-col gap-6 mr-48">
                <Typography variant="h2">Apps</Typography>
                <div>Apps provide rich AI experiences</div>
            </div>
        </DocsLayout>
    )
}