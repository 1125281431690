import React from "react";
import DocsLayout from "../../components/DocsLayout";
import InlineCode from "../../components/InlineCode";
import InlineLink from "../../components/InlineLink";
import { H1, H2, H3, P, CodeSnippet, CodeFile } from "../../components/Docs";

const agentCode = `
from agentsea import Agent

class Vega(Agent):
    """An agent that researches for you"""

    @classmethod
    def objective(cls) -> str:
        return "Find articles based on the user's interests"

    ...

`;

const scriptCode = `
from agentsea.script import Process, script, wait_for_ack

@script(description="An introduction for Vega the researcher")
def intro(process: Process) -> Output:
    ack = wait_for_ack(
        process, "Hi I'm Vega, I'll help you with academic research"
    )
    ...

`;

const echoCode = `
from agentsea import Reaction, Agent, Event

class EchoReaction(Reaction):
    
    def react(self, agent: Agent, event: Event) -> None:
        print(f"event: {event}")

    def should_react(self, event: Event) -> bool:
        return True

`;

const reactClassCode = `
from .react import EchoReaction

class Vega(Agent):
    
    def reactions(self) -> List[Reaction]:
        return [
            EchoReaction,
        ]
    ...

`;

const toolsCode = `
from agentsea import Agent
from agentsea.tool import Tool, ArXiv

class Vega(Agent):

    @classmethod
    def tools(cls) -> List[Tool]:
        return [ArXiv()]
    ...

`;

export default function AgentsDocsPage() {
  return (
    <DocsLayout>
      <H1>Agents</H1>
      <P>Build an AI agent which acts in the world</P>
      <H2>Initialize an agent repo</H2>
      <P>From an empty repository</P>
      <CodeSnippet text="agentsea create agent [NAME]" language="text" />
      <H2 className="mt-96">Implement agent</H2>
      <P>
        Navigate to the <InlineCode code="agent.py" /> file and complete the
        class definition
      </P>
      <CodeFile text={agentCode} language="python" fileName="agent.py" />
      <H3>Reactions</H3>
      <P>
        <InlineLink to="/docs/agent/reactions" text="Reactions" /> enable your
        agent to react to events in the world. Add any custom reactions in
        <InlineCode code="react.py" />
      </P>
      <P>
        For example, the following reaction will simply print any event it
        recieves.
      </P>
      <CodeFile text={echoCode} language="python" fileName="react.py" />
      <P>
        Add the reaction to the agent class to have it start processing events
      </P>
      <CodeFile text={reactClassCode} language="python" fileName="agent.py" />
      <H3>Tools</H3>
      <P>
        <InlineLink to="/docs/tools/overview" text="Tools" /> enable your agent
        to interact with the world.
      </P>
      <P>
        For example, adding the ArXiv tool will enable reactions to utilize it
        <i>e.g. a user asks for the latest research in a chat.</i>
      </P>
      <CodeFile text={toolsCode} language="python" fileName="agent.py" />
      <H3>Scripts</H3>
      <P>
        <InlineLink to="/docs/agent/scripts" text="Scripts" /> provide
        deterministic behavior for your agent. Create an introduction script for
        your agent as well as one to subscribe new users
      </P>
      <CodeFile text={scriptCode} language="python" fileName="script.py" />
      <H2>Deploy agent</H2>
      <P>Deploy your agent to any Kubernetes cluster or on our cloud</P>
      <CodeSnippet text="agentsea deploy ." language="text" />
      <H2>View agent</H2>
      <P>Connect to the running agent and view the UI</P>
      <CodeSnippet text="agentsea view [NAME]" language="text" />
      <br />
      <P>
        See <InlineLink to="/docs/tutorials" text="tutorials" /> for more in
        depth examples
      </P>
    </DocsLayout>
  );
}
