import React, { useState, useContext } from "react";
import { Typography, Input, Button, Spinner } from "@material-tailwind/react";
import { remoteAgentInfo, registerAgent } from "../api/Agents";
import { XCircleIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import { AuthContext } from "../server/AuthContext";

export default function RegisterToolModal({ onClose }) {
  const { token } = useContext(AuthContext);
  console.log("got token from auth context: ", token);

  const [toolUrl, setToolUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);

  const handleInputChange = (e) => {
    setToolUrl(e.target.value);
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Sending URL to backend:", toolUrl);
    // Example: fetch('your-backend-endpoint', { method: 'POST', body: JSON.stringify({ url: agentUrl }), ... });

    setLoading(true);

    // remoteAgentInfo(agentUrl, token)
    //   .then((response) => {
    //     // Handle the response here
    //     console.log("Agent registered:", response);
    //     setLoading(false);

    //     return registerAgent(
    //       response.name,
    //       response.description,
    //       response.url,
    //       response.icon_url,
    //       token
    //     );
    //   })
    //   .then(() => {
    //     setDone(true);
    //     setLoading(false);
    //   })
    //   .then(() => sleep(1000))
    //   .then(() => onClose())
    //   .catch((error) => {
    //     // Handle any errors here
    //     console.error("Error registering agent:", error);
    //     setError(true);
    //     setLoading(false);
    //   });
  };

  return (
    <div>
      {loading && <Spinner className="h-12 w-12" />}
      {error && (
        <div className="flex flex-row">
          <XCircleIcon className="mr-2" />
          <Typography className="text-2xl font-bold mb-4">
            Error occurred while registering
          </Typography>
        </div>
      )}
      {done && (
        <div className="flex flex-row">
          <CheckCircleIcon className="mr-2" />
          <Typography className="text-2xl font-bold mb-4">
            Registration successful!
          </Typography>
        </div>
      )}

      {!loading && !done && !error && (
        <>
          <Typography className="text-2xl font-bold mb-4">
            Enter the URL of the tool
          </Typography>
          <form onSubmit={handleSubmit}>
            <Input
              className=""
              label="Tool URL"
              type="text"
              value={toolUrl}
              onChange={handleInputChange}
            />
            <Button
              className="float-right mt-2 mb-4"
              variant="outlined"
              rounded
              type="submit"
            >
              Register
            </Button>
          </form>
        </>
      )}
    </div>
  );
}
