import React from "react";
import { Typography } from "@material-tailwind/react";
import DocsLayout from "../../components/DocsLayout";

export default function ToolOverviewPage() {
  return (
    <DocsLayout>
      <div className="flex flex-col gap-6 mr-48">
        <Typography variant="h2">Tools</Typography>
        <div>Tools are a means for agents to interact with the world</div>
        <div className="flex items-center justify-center">
          <img
            src="https://storage.googleapis.com/agentsea-assets/agent_diagram.svg"
            alt="agent diagram"
            className="w-8/12 h-8/12"
          />
        </div>
        <div></div>
      </div>
    </DocsLayout>
  );
}
