import React from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  MenuItem,
} from "@material-tailwind/react";
import {
  UserCircleIcon,
  CodeBracketSquareIcon,
  ComputerDesktopIcon,
  CloudIcon,
  PresentationChartLineIcon,
  ClipboardIcon,
} from "@heroicons/react/24/outline";

import { Link } from "react-router-dom";

// nav list component
const navListItems = [
  // {
  //   label: "Apps",
  //   icon: CodeBracketSquareIcon,
  //   route: "/apps",
  // },
  {
    label: "Agents",
    icon: UserCircleIcon,
    route: "/agents",
  },
  // {
  //   label: "Devices",
  //   icon: ComputerDesktopIcon,
  //   route: "/devices",
  // },
  {
    label: "Tasks",
    icon: ClipboardIcon,
    route: "/tasks",
  },
  {
    label: "Boards",
    icon: PresentationChartLineIcon,
    route: "/boards",
  },
  // {
  //   label: "Blocks",
  //   icon: CubeTransparentIcon,
  //   route: "/blocks",
  // },
  {
    label: "Docs",
    icon: CodeBracketSquareIcon,
    route: "/docs",
  },
];
function NavList() {
  return (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center">
      {navListItems.map(({ label, icon, route }, key) => (
        <Link to={route}>
          <Typography
            key={label}
            as="a"
            href="#"
            variant="small"
            color="blue-gray"
            className="font-normal"
          >
            <MenuItem className="flex items-center gap-2 lg:rounded-full">
              {React.createElement(icon, { className: "h-[18px] w-[18px]" })}{" "}
              {label}
            </MenuItem>
          </Typography>
        </Link>
      ))}
    </ul>
  );
}

export function LandingNav() {
  const [isNavOpen, setIsNavOpen] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsNavOpen(false)
    );
  }, []);

  return (
    <Navbar className="mx-auto max-w-screen-xl p-2 lg:rounded-full mt-4 lg:pl-6">
      <div className="relative mx-auto flex items-center text-blue-gray-900">
        <img
          className="h-9 w-9 rounded-full object-contain object-center rounded-full"
          src="https://storage.googleapis.com/guisurfer-assets/logo_black.png"
          alt="logo"
        />
        <div className="absolute top-2/4 left-2/4 hidden -translate-x-2/4 -translate-y-2/4 lg:block">
          <NavList />
        </div>
        <Link to="/login" className="ml-auto mr-2">
          <Button size="sm">Login</Button>
        </Link>
      </div>
      <MobileNav open={isNavOpen} className="overflow-scroll">
        <NavList />
      </MobileNav>
    </Navbar>
  );
}
