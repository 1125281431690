import Task from "../components/Task";
import Layout from "../components/Layout";
import { getAPIAddr } from "../api/Env";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../server/AuthContext";
import { useEffect, useState } from "react";
import { getTask } from "../api/Tasks";
import { Spinner } from "@material-tailwind/react";

export default function TaskPage() {
  const { token } = useContext(AuthContext);
  const [task, setTask] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    if (token !== undefined) {
      setLoading(true);
      getTask(id, token).then((data) => {
        setTask(data);
        setLoading(false);
      });
      // Then set the interval
      const intervalId = setInterval(async () => {
        getTask(id, token).then((data) => {
          setTask(data);
          setLoading(false);
        });
      }, 2000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [token, id]);

  if (loading) {
    // If the component is still loading, show a spinner
    return (
      <Layout>
        <div className="flex justify-center items-center mt-72">
          <Spinner className="h-24 w-24" />
        </div>
      </Layout>
    );
  }
  return (
    <Layout>
      <div className="mt-12">
        <Task data={task} addr={getAPIAddr()} token={token}></Task>
      </div>
    </Layout>
  );
}
