import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { AuthContext } from "../server/AuthContext";

export function BlockCard({ name, description, bgcolor, imgURL, route }) {
    return (
        <motion.div
            initial={{ scale: 1 }}
            whileHover={{ scale: 1.02 }}
        >
            <Link to={route}>
                <Card className="mt-6 w-80 mr-6 cursor-pointer">

                    <CardHeader style={{ backgroundColor: bgcolor }} className="p-0 m-0">
                        <img
                            src={imgURL}
                            alt="card-image"
                        // className="border border-black"
                        // style={{ width: '50%', height: '50%', objectFit: 'cover', margin: 'auto', borderRadius: '50%' }}
                        />
                    </CardHeader>
                    <CardBody>
                        <Typography variant="h5" color="blue-gray" className="mb-2">
                            {name}
                        </Typography>
                        <Typography>
                            {description}
                        </Typography>
                    </CardBody>
                    <CardFooter className="pt-0">
                        <a href="#" className="inline-block">
                            <Button size="sm" variant="text" className="flex items-center gap-2">
                                Get Started
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="currentColor"
                                    className="h-4 w-4"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                    />
                                </svg>
                            </Button>
                        </a>
                    </CardFooter>
                </Card>
            </Link>
        </motion.div>
    );
}