
export default function Footer() {
    return (
        <footer class="bg-white rounded-lg shadow m-4 mt-36">
            <div class="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
                <span class="text-sm text-gray-500 sm:text-center">
                    © 2023{" "}
                    <a href="https://agentsea.com/" class="hover:underline">
                        Kentauros Inc.
                    </a>{" "}
                    All Rights Reserved.
                </span>
                <ul class="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 sm:mt-0">
                    <li>
                        <a href="#" class="mr-4 hover:underline md:mr-6 ">
                            About
                        </a>
                    </li>
                    <li>
                        <a href="#" class="mr-4 hover:underline md:mr-6">
                            Privacy Policy
                        </a>
                    </li>
                    <li>
                        <a href="/support" class="mr-4 hover:underline md:mr-6">
                            Support
                        </a>
                    </li>
                    <li>
                        <a href="/support" class="hover:underline">
                            Contact
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    )
}