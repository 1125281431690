import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
} from "@material-tailwind/react";
import { UserCircleIcon, KeyIcon } from "@heroicons/react/24/solid";

export function ProfileSidebar({ selectedItem, setSelectedItem }) {
  return (
    <Card className="h-[calc(50vh-2rem)] w-full min-w-[10rem] max-w-[20rem] p-4 shadow-md rounded-3xl">
      <div className="mb-2 p-4">
        <Typography variant="h5" color="blue-gray">
          Profile
        </Typography>
      </div>
      <List>
        <ListItem
          selected={selectedItem === "General"}
          onClick={() => setSelectedItem("General")}
        >
          <ListItemPrefix>
            <UserCircleIcon className="h-4 w-4" />
          </ListItemPrefix>
          General
        </ListItem>
        <ListItem
          selected={selectedItem === "API Keys"}
          onClick={() => setSelectedItem("API Keys")}
        >
          <ListItemPrefix>
            <KeyIcon className="h-4 w-4" />
          </ListItemPrefix>
          API Keys
        </ListItem>
      </List>
    </Card>
  );
}
