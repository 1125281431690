import { Link } from "react-router-dom";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";

export default function InlineLink({ to, text }) {
  return (
    <Link to={to}>
      <div className="flex flex-row items-end ml-1 mr-1 w-fit">
        <ArrowTopRightOnSquareIcon className="h-4 w-4 mb-1" />
        {text}
      </div>
    </Link>
  );
}
