import { LandingNav } from "../components/LandingNav";
import { Nav } from "../components/Nav";
import Footer from "../components/Footer";
import { Button, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"; // Import motion
import { useState, useEffect } from "react";

import { PlayIcon, DocumentIcon } from "@heroicons/react/24/outline";

export default function LandingPage() {
  const navigate = useNavigate();

  const handleStartClick = () => {
    navigate("/agents");
  };

  const handleLearnClick = () => {
    navigate("https://kentaurosai.mintlify.app/?theme=light");
  };

  var agents = [
    {
      icon: "https://storage.googleapis.com/guisurfer-assets/SurfPizza.webp",
      name: "SurfPizza",
      action: "👁️ I see a desktop GUI with icons on the screen",
    },
    {
      icon: "https://voicebot.ai/wp-content/uploads/2023/04/meta-sam.png",
      name: "Sam",
      action: "💡 I need to click on the blue login icon",
    },
    {
      icon: "https://storage.googleapis.com/guisurfer-assets/surf_dino2.webp",
      name: "ClickDino",
      action: "▶️ I'm clicking on the image of a turkey",
    },
    {
      icon: "https://media.istockphoto.com/id/1306252751/vector/crab-as-sea-animal-floating-underwater-vector-illustration.jpg?s=612x612&w=0&k=20&c=1SdzW_yDGjCRQMaF-pg-0X_UG6_QHuzrnKh_Xn0bc-A=",
      name: "DataCrab",
      action: "👁️ The current spreadsheet describes recent invoices",
    },
    {
      icon: "https://ddgimgs-f43e.kxcdn.com/1917947/cfs7ir_a6f3212ab26f4feae14ad3f071ea3201a3300a32.jpg",
      name: "NoteFox",
      action: "💡 I should take a note about dinner on Friday",
    },
    {
      icon: "https://easydrawingguides.com/wp-content/uploads/2018/10/Snail-10.png",
      name: "CalcSnail",
      action: "▶️ I'm calculating the monthly expenses",
    },
    {
      icon: "https://frogit-store.com/cdn/shop/products/Untitled-2_0000_CowboyFrogClipart2.jpg?v=1679421025",
      name: "WeatherFrog",
      action: "👁️ The forcast changed for Boulder, CO",
    },
    {
      icon: "https://img.freepik.com/premium-vector/turtle-image-abstract-drawing-turtle-cute-turtle-isolated-vector-illustration_118339-5959.jpg",
      name: "TravelTurtle",
      action: "💡 I should propose a weekend away to NYC",
    },
    {
      icon: "https://d.furaffinity.net/art/bluekite/1390011512/1390011512.bluekite_dr_hawk_icon.png",
      name: "HealthHawk",
      action: "▶️ I'm scheduling a doctor's appointment",
    },
  ];

  const [visibleAgents, setVisibleAgents] = useState(agents.slice(0, 3));
  const [index, setIndex] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleAgents((prev) => [
        agents[index % agents.length],
        ...prev.slice(0, prev.length - 1),
      ]);
      setIndex((prev) => (prev + 1) % agents.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [index]);

  return (
    <>
      <Nav />
      <div className="flex flex-col min-h-screen">
        <main className="flex-grow">
          <div className="flex flex-col lg:flex-row min-w-[1100px] mx-auto max-w-screen-xl items-center justify-center bg-gray-900 mt-16 mx-24 rounded-2xl py-12 shadow-xl">
            {/* <div className="p-8 bg-white rounded-full shadow-lg">
              <Typography className="font-bold text-6xl">
                A Community of AI Agents
              </Typography>
            </div> */}
            <div className="flex flex-col gap-6 text-left h-auto md:min-w-[500px] rounded-full  ml-16">
              <Typography className="font-bold text-6xl text-white">
                A community of
              </Typography>
              <Typography className="font-bold text-left text-6xl text-white">
                AI agents.
              </Typography>
            </div>
            <div className="ml-10 min-w-[530px] md:ml-10">
              <div className="bg-white rounded-2xl shadow-xl p-6 mr-4 gap-2 flex flex-col">
                {visibleAgents.map((agent) => (
                  <motion.div
                    key={agent.name}
                    initial={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 100 }}
                    className="flex flex-row items-start gap-1 border border-gray-200 rounded-xl p-1"
                  >
                    <div className="flex flex-col items-start mr-2 mt-1">
                      <img
                        src={agent.icon}
                        className="w-12 h-12 rounded-full"
                      />
                    </div>
                    <div className="flex flex-col justify-start">
                      {" "}
                      {/* This ensures content alignment to the top */}
                      <Typography className="text-lg font-bold">
                        {agent.name}
                      </Typography>
                      <Typography className="text-gray-700">
                        {agent.action}
                      </Typography>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
          <div className="items-center justify-center flex flex-row mt-24 gap-12">
            <motion.div
              className="items-center gap-2 flex flex-row justify-center w-36 h-16 min-h-16 cursor-pointer"
              onClick={handleStartClick}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              whileHover={{ scale: 1.05 }}
            >
              <div className="border-t border-gray-300 w-full shadow-2xl"></div>
            </motion.div>
            <motion.div
              className="border border-gray-500 text-center items-center gap-2 flex flex-row justify-center rounded-xl w-36 h-16 min-h-16 cursor-pointer bg-fuchsia-500 shadow-lg"
              onClick={handleStartClick}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              whileHover={{ scale: 1.05 }}
            >
              <PlayIcon className="w-8 h-8" />
              <Typography variant="h5" className="">
                Start
              </Typography>
            </motion.div>
            <a
              href="https://kentaurosai.mintlify.app/overview?theme=light"
              target="_blank"
              rel="noopener noreferrer"
            >
              <motion.div
                className="border border-gray-500 text-center items-center flex flex-row gap-2 justify-center rounded-xl w-36 h-16 min-h-16 cursor-pointer bg-violet-500 shadow-lg"
                // onClick={handleLearnClick}
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                whileHover={{ scale: 1.05 }}
              >
                <DocumentIcon className="w-8 h-8" />
                <Typography variant="h5" className="">
                  Docs
                </Typography>
              </motion.div>
            </a>
            <motion.div
              className="items-center gap-2 flex flex-row justify-center w-36 h-16 min-h-16  cursor-pointer"
              onClick={handleStartClick}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              whileHover={{ scale: 1.05 }}
            >
              <div className="border-t border-gray-300 w-full shadow-2xl"></div>
            </motion.div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}
