import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Input,
  Button,
  Spinner,
  Select,
  Option,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { remoteAgentInfo, createAgent, fetchLLMProviders } from "../api/Agents";
import { XCircleIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import { AuthContext } from "../server/AuthContext";
import { listDesktopRuntimes, listAgentRuntimes } from "../api/Runtime";
import { getAgentTypes } from "../api/Types";

export default function RegisterAgentModal({ onClose }) {
  const { token } = useContext(AuthContext);
  // console.log("got token from auth context: ", token);
  const navigate = useNavigate();

  const [agentUrl, setAgentUrl] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [desktopRuntime, setDesktopRuntime] = useState("");
  const [agentRuntime, setAgentRuntime] = useState("");
  const [providers, setProviders] = useState([]);
  const [agentRuntimes, setAgentRuntimes] = useState([]);
  const [fetchingDesktopRuntimes, setFetchingDesktopRuntimes] = useState(true);
  const [fetchingAgentRuntimes, setFetchingAgentRuntimes] = useState(true);
  const [agentTypes, setAgentTypes] = useState([]);
  const [selectedAgentType, setSelectedAgentType] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [envOptInputs, setEnvOptInputs] = useState({});
  const [envVars, setEnvVars] = useState({});
  const [secrets, setSecrets] = useState({});

  const [selectedLLMProvider, setSelectedLLMProvider] = useState("");
  const [llmProviders, setLLMProviders] = useState([]);
  const [llmEnvVar, setLLMEnvVar] = useState({});
  const [backupLLMProvider, setBackupLLMProvider] = useState("");
  const [backupLLMEnvVar, setBackupLLMEnvVar] = useState({});
  const [versions, setVersions] = useState({});
  const [selectedVersion, setSelectedVersion] = useState("default");

  useEffect(() => {
    if (token) {
      getAgentTypes(token)
        .then((data) => {
          setAgentTypes(data || []);
        })
        .catch((error) => {
          console.error("Error fetching agent types:", error);
        });

      const intervalId = setInterval(async () => {
        var types = await getAgentTypes(token);
        console.log("setting types: ");
        console.log(types);
        setAgentTypes(types || []);
      }, 2000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [token]);

  useEffect(() => {
    if (selectedAgentType) {
      const agentType = agentTypes.find(
        (type) => type.name === selectedAgentType
      );
      setSelectedAgent(agentType);

      console.log("!?setting agentType: ");
      console.log(agentType);

      if (agentType && agentType.llm_providers) {
        // Fetch LLM provider options with error handling
        console.log("!?fetching llm providers");
        fetchLLMProviders(selectedAgentType, token)
          .then((data) => {
            // Ensure the data is an array before setting it
            console.log("!?setting llmProviders: ");
            console.log(data);
            setLLMProviders(data);
          })
          .catch((error) => {
            console.error("Error fetching LLM providers:", error);
            // Set llmProviders to an empty array in case of error
            setLLMProviders([]);
          });
      }
      setSelectedVersion("default");
      if (agentType && agentType.versions) {
        console.log("!?versions: ", agentType.versions);
        const newVersions = {
          default: agentType.image, // Use a general or default image if available
          ...agentType.versions,
        };
        setVersions(newVersions);
        setSelectedVersion("default");
      }
    } else {
      // Optionally reset llmProviders when there's no selected agent type
      setLLMProviders([]);
    }
  }, [selectedAgentType, token]);

  const onLLMProviderSelected = (e) => {
    const selectedProviderModel = e; // Assuming this is the value/model of the selected provider
    console.log("!?selected provider model: ", selectedProviderModel);
    const selectedProvider = llmProviders.find(
      (provider) => provider.model === selectedProviderModel
    );
    console.log("!?selected provider: ", selectedProvider);

    if (selectedProvider && selectedProvider.env_var) {
      // If it's indeed a singular env_var
      console.log("!?setting selected LLM provider: ", selectedProviderModel);
      setSelectedLLMProvider(selectedProviderModel);
      console.log("!?setting env var: ", selectedProvider.env_var);
      setLLMEnvVar(selectedProvider.env_var);
    }
  };

  const onBackupLLMProviderSelected = (e) => {
    const selectedBackupProviderModel = e;
    const selectedBackupProvider = llmProviders.find(
      (provider) => provider.model === selectedBackupProviderModel
    );

    if (selectedBackupProvider) {
      console.log(
        "!?setting selected backup LLM provider: ",
        selectedBackupProviderModel
      );
      setBackupLLMProvider(selectedBackupProviderModel);
      console.log("!?setting backup env var: ", selectedBackupProvider.env_var);
      setBackupLLMEnvVar(selectedBackupProvider.env_var);
    }
  };

  const setSelectedVersionHandler = (e) => {
    console.log("!?setting selected version: ", e);
    setSelectedVersion(e);
  };

  useEffect(() => {
    console.log("!?llm providers: ", llmProviders);
  }, [llmProviders]);

  useEffect(() => {
    if (token !== undefined) {
      setFetchingDesktopRuntimes(true);
      listDesktopRuntimes(token).then((data) => {
        setProviders(data);
        setFetchingDesktopRuntimes(false);
      });
      // Then set the interval
      const intervalId = setInterval(async () => {
        var providers = await listDesktopRuntimes(token);
        console.log("setting providers: ");
        console.log(providers);
        setProviders(providers);
      }, 2000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [token]);

  useEffect(() => {
    if (token !== undefined) {
      setFetchingAgentRuntimes(true);
      listAgentRuntimes(token).then((data) => {
        setAgentRuntimes(data);
        setFetchingAgentRuntimes(false);
      });
      // Then set the interval
      const intervalId = setInterval(async () => {
        var providers = await listAgentRuntimes(token);
        console.log("setting providers: ");
        console.log(providers);
        setAgentRuntimes(providers);
      }, 2000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [token]);

  useEffect(() => {
    console.log("!?versions set: ", versions);
    console.log("!?selected version set: ", selectedVersion);
  }, [versions, selectedVersion]);

  const handleCreateProvider = (e) => {
    navigate("/runtimes");
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getImageUrlForProviderType = (providerType) => {
    const imageMapping = {
      ec2: "https://www.logicata.com/wp-content/uploads/2020/01/EC2.png",
      gce: "https://download.logo.wine/logo/Google_Compute_Engine/Google_Compute_Engine-Logo.wine.png",
      gke: "https://download.logo.wine/logo/Google_Compute_Engine/Google_Compute_Engine-Logo.wine.png",
    };

    return (
      imageMapping[providerType] ||
      "https://iheartcraftythings.com/wp-content/uploads/2021/06/Cloud_5-1.jpg"
    );
  };

  const isFetchingRuntimes = fetchingDesktopRuntimes || fetchingAgentRuntimes;

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Sending URL to backend:", agentUrl);
    // Example: fetch('your-backend-endpoint', { method: 'POST', body: JSON.stringify({ url: agentUrl }), ... });

    // // Check if all required env_opts have been filled
    // const selectedTypeEnvOpts = agentTypes.find(
    //   (type) => type.name === selectedAgentType
    // ).env_opts;
    // const missingRequiredOpts = selectedTypeEnvOpts.filter(
    //   (opt) => opt.required && !envOptInputs[opt.name]
    // );

    // if (missingRequiredOpts.length > 0) {
    //   // Handle missing required env_opts (e.g., show an error message)
    //   console.error(
    //     "Missing required fields:",
    //     missingRequiredOpts.map((opt) => opt.name).join(", ")
    //   );
    //   return;
    // }

    setLoading(true);

    // class CreateDesktopModel(BaseModel):
    // runtime: str
    // ssh_key_name: Optional[str] = None
    // gce_opts: Optional[GCEProviderOptions] = None
    // ec2_opts: Optional[EC2ProviderOptions] = None
    // name: Optional[str] = None
    // image: Optional[str] = None
    // memory: int = 4
    // cpu: int = 2
    // disk: str = "30gb"
    // tags: Optional[Dict[str, str]] = None
    // reserve_ip: bool = False

    const updatedSecrets = {
      ...secrets,
      // Add the primary LLM provider's environment variable, if selected and it exists
      ...(selectedLLMProvider && llmEnvVar.name
        ? { [llmEnvVar.name]: llmEnvVar.default }
        : {}),
      // Conditionally include the backup LLM provider's environment variable, if selected and it exists
      ...(backupLLMProvider && backupLLMEnvVar.name
        ? { [backupLLMEnvVar.name]: backupLLMEnvVar.default }
        : {}),
    };

    // Add new env var
    const prefEnvVar = {
      MODEL_PREFERENCE: `${selectedLLMProvider},${backupLLMProvider}`,
    };

    createAgent(
      name,
      agentRuntime,
      selectedAgentType,
      { runtime: desktopRuntime, name: name },
      {},
      { ...envVars, ...prefEnvVar },
      updatedSecrets,
      true,
      selectedVersion,
      token
    )
      .then((response) => {
        // Handle the response here
        console.log("Agent created:", response);
        setLoading(false);
      })
      .then(() => {
        setDone(true);
        setLoading(false);
        navigate("/agents/" + name);
      })
      .then(() => sleep(1000))
      .then(() => onClose())
      .catch((error) => {
        // Handle any errors here
        console.error("Error creating agent:", error);
        setError(true);
        setLoading(false);
      });
  };

  function isNotEmpty(obj) {
    return Object.keys(obj).length > 0;
  }

  return (
    <>
      {isFetchingRuntimes ? (
        <div className="text-center">
          <Spinner className="h-12 w-12" />
        </div>
      ) : providers.length > 0 && agentRuntimes.length > 0 ? (
        <div className="gap-4">
          {loading && <Spinner className="h-12 w-12" />}
          {error && (
            <div className="flex flex-row">
              <XCircleIcon className="mr-2 h-12 w-12" />
              <Typography className="text-2xl font-bold mb-4">
                Error occurred while registering
              </Typography>
            </div>
          )}
          {done && (
            <div className="flex flex-row">
              <CheckCircleIcon className="mr-2 h-12 w-12" />
              <Typography className="text-2xl font-bold mb-4">
                Agent creation started!
              </Typography>
            </div>
          )}

          {!loading && !done && !error && (
            <div className="">
              <form onSubmit={handleSubmit} className="">
                <div className="mb-4">
                  <Input
                    className=""
                    label="Name of the surfer"
                    type="text"
                    value={name}
                    onChange={handleNameChange}
                  />
                </div>
                <div className="mb-4">
                  <Select
                    value={desktopRuntime}
                    onChange={(e) => setDesktopRuntime(e)}
                    label="Select desktop runtime"
                    selected={(element) =>
                      element &&
                      React.cloneElement(element, {
                        disabled: true,
                        className:
                          "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
                      })
                    }
                  >
                    {providers &&
                      providers?.map((provider) => (
                        <Option
                          value={provider.name}
                          className="flex items-center gap-2"
                        >
                          {" "}
                          <img
                            src={getImageUrlForProviderType(provider.provider)}
                            alt={provider.name}
                            className="h-5 w-5 rounded-full object-cover"
                          />
                          {provider.name}
                        </Option>
                      ))}
                  </Select>
                </div>
                <div className="mb-4">
                  <Select
                    value={agentRuntime}
                    onChange={(e) => setAgentRuntime(e)}
                    label="Select agent runtime"
                    selected={(element) =>
                      element &&
                      React.cloneElement(element, {
                        disabled: true,
                        className:
                          "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
                      })
                    }
                  >
                    {agentRuntimes &&
                      agentRuntimes.map((provider) => (
                        <Option
                          value={provider.name}
                          className="flex items-center gap-2"
                        >
                          {" "}
                          <img
                            src={getImageUrlForProviderType(provider.provider)}
                            alt={provider.name}
                            className="h-5 w-5 rounded-full object-cover"
                          />
                          {provider.name}
                        </Option>
                      ))}
                  </Select>
                </div>
                <div className="mb-4">
                  <Select
                    value={selectedAgentType}
                    key={selectedAgentType}
                    onChange={(e) => setSelectedAgentType(e)}
                    label="Select agent type"
                    selected={(element) =>
                      element &&
                      React.cloneElement(element, {
                        disabled: true,
                        className:
                          "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
                      })
                    }
                  >
                    {agentTypes &&
                      agentTypes.map((type) => (
                        <Option
                          value={type.name}
                          className="flex items-center gap-2"
                        >
                          {" "}
                          <img
                            src={type.icon}
                            alt={type.name}
                            className="h-5 w-5 rounded-full object-cover"
                          />
                          {type.name}
                        </Option>
                      ))}
                    {/* <Option value="DinOCR">{"DinOCR"}</Option> */}
                  </Select>
                </div>
                {isNotEmpty(versions) && (
                  <div className="mb-4">
                    <Select
                      key={selectedVersion}
                      label="Select Version"
                      value={selectedVersion}
                      onChange={(e) => setSelectedVersionHandler(e)}
                    >
                      {Object.entries(versions).map(
                        ([versionName, imageUri]) => (
                          <Option
                            key={versionName}
                            value={versionName}
                          >{`${versionName}: ${imageUri}`}</Option>
                        )
                      )}
                    </Select>
                  </div>
                )}
                {llmProviders.length > 0 && (
                  <div className="mb-4">
                    <Select
                      key={selectedLLMProvider}
                      label="Select LLM Provider"
                      value={selectedLLMProvider}
                      onChange={onLLMProviderSelected}
                    >
                      {llmProviders &&
                        llmProviders?.map((provider) => (
                          <Option key={provider.model} value={provider.model}>
                            {provider.model}
                          </Option>
                        ))}
                    </Select>

                    {/* Conditionally render inputs for each environment variable */}
                    {selectedLLMProvider && llmEnvVar && (
                      <div className="mb-4 mt-4">
                        <Input
                          type={llmEnvVar.secret ? "password" : "text"}
                          value={llmEnvVar.default || ""}
                          onChange={(e) =>
                            setLLMEnvVar({
                              ...llmEnvVar,
                              default: e.target.value,
                            })
                          }
                          required={true}
                          label={llmEnvVar.name}
                          size="md"
                        />
                      </div>
                    )}
                  </div>
                )}
                {llmProviders.length > 0 && (
                  <div className="mb-4">
                    <Select
                      key={backupLLMProvider}
                      label="Select Backup LLM Provider (Optional)"
                      value={backupLLMProvider}
                      onChange={onBackupLLMProviderSelected}
                    >
                      {llmProviders
                        .filter((p) => p.model !== selectedLLMProvider)
                        .map((provider) => (
                          <Option key={provider.model} value={provider.model}>
                            {provider.model}
                          </Option>
                        ))}
                    </Select>

                    {/* Conditionally render inputs for each environment variable of the backup provider */}
                    {backupLLMProvider && backupLLMEnvVar && (
                      <div className="mb-4 mt-4">
                        <Input
                          type={backupLLMEnvVar.secret ? "password" : "text"}
                          value={backupLLMEnvVar.default || ""}
                          onChange={(e) =>
                            setBackupLLMEnvVar({
                              ...backupLLMEnvVar,
                              default: e.target.value,
                            })
                          }
                          required={true}
                          label={backupLLMEnvVar.name}
                          size="md"
                        />
                      </div>
                    )}
                  </div>
                )}

                {selectedAgentType && agentTypes.length > 0 && (
                  <>
                    {agentTypes
                      .find((type) => type.name === selectedAgentType)
                      .env_opts.map((opt, index) => (
                        <div key={index} className="mb-4">
                          {opt.options && opt.options.length > 0 ? (
                            <Select
                              label={opt.name}
                              value={
                                opt.secret
                                  ? secrets[opt.name]
                                  : envVars[opt.name] || ""
                              }
                              onChange={(e) => {
                                const value = e;
                                if (opt.secret) {
                                  setSecrets((prev) => ({
                                    ...prev,
                                    [opt.name]: value,
                                  }));
                                } else {
                                  setEnvVars((prev) => ({
                                    ...prev,
                                    [opt.name]: value,
                                  }));
                                }
                              }}
                              required={opt.required}
                            >
                              {opt.options.map((optionValue) => (
                                <Option key={optionValue} value={optionValue}>
                                  {optionValue}
                                </Option>
                              ))}
                            </Select>
                          ) : (
                            <Input
                              label={opt.name}
                              type={opt.secret ? "password" : "text"}
                              value={
                                opt.secret
                                  ? secrets[opt.name]
                                  : envVars[opt.name] || ""
                              }
                              onChange={(e) => {
                                const value = e.target.value;
                                if (opt.secret) {
                                  setSecrets((prev) => ({
                                    ...prev,
                                    [opt.name]: value,
                                  }));
                                } else {
                                  setEnvVars((prev) => ({
                                    ...prev,
                                    [opt.name]: value,
                                  }));
                                }
                              }}
                              required={opt.required}
                            />
                          )}
                        </div>
                      ))}
                  </>
                )}

                <Button
                  className="float-right mt-2 mb-4 w-full"
                  variant="outlined"
                  rounded
                  type="submit"
                >
                  Create
                </Button>
              </form>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="mb-4 text-center">
            <Typography variant="h4" color="blue">
              No runtimes found
            </Typography>
          </div>
          <Button
            variant="outlined"
            className="w-full"
            onClick={handleCreateProvider}
          >
            Create a Runtime
          </Button>
        </div>
      )}
    </>
  );
}
