import React, { useState, useEffect, useRef } from "react";
import { Typography, Avatar, Dialog, Button } from "@material-tailwind/react";
import { motion } from "framer-motion";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";

const RoleThread = ({ data, expanded, toggleExpanded, isExpandable }) => {
  const messages = data?.messages || [];
  const roleMapping = data?.role_mapping || {};
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const endOfMessagesRef = useRef(null);

  const handleImageClick = (image) => {
    setCurrentImage(image);
    setModalOpen(true);
  };

  useEffect(() => {
    const scrollContainer = endOfMessagesRef.current?.parentNode;
    if (scrollContainer && endOfMessagesRef.current) {
      scrollContainer.scrollTo({
        top: scrollContainer.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages.length, expanded, isExpandable]);

  return (
    <div className="flex flex-col gap-2 relative border border-black-400 min-h-[200px] w-full">
      <div
        className={`relative flex flex-col ${
          expanded || !isExpandable ? "" : "max-h-[300px] overflow-hidden"
        }`}
      >
        {messages.map((msg, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="border border-black-400 p-3 bg-neutral-50 rounded-xl flex flex-col mt-1"
          >
            <div className="flex flex-row items-center gap-2">
              <Avatar
                src={
                  roleMapping[msg.role]?.icon ||
                  "https://cdn-icons-png.flaticon.com/512/1053/1053244.png"
                }
                alt="Role Avatar"
                className="w-5 h-5"
              />
              <Typography variant="small" className="font-semibold">
                {roleMapping[msg.role]?.user_name || msg.role}
              </Typography>
            </div>
            <Typography variant="small" className="font-mono mb-2 mt-2">
              {msg.text}
            </Typography>
            {msg.images &&
              msg.images.map((image, imgIndex) => (
                <img
                  key={imgIndex}
                  src={image}
                  alt={`Message Attachment ${imgIndex}`}
                  className="w-16 h-16 cursor-pointer mt-2"
                  onClick={() => handleImageClick(image)}
                />
              ))}
          </motion.div>
        ))}
        <div ref={endOfMessagesRef} />
      </div>
      {!expanded && isExpandable && (
        <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-white to-transparent flex justify-center items-end pointer-events-none z-10">
          <Button
            size="sm"
            className="mb-2 pointer-events-auto flex flex-row gap-2 items-center"
            onClick={toggleExpanded}
            variant="outlined"
          >
            <PlusIcon className="w-4 h-4 text-black-500" />
            Expand
          </Button>
        </div>
      )}
      {expanded && isExpandable && (
        <Button
          size="sm"
          className="mt-2 self-center flex flex-row gap-2 items-center"
          onClick={toggleExpanded}
          variant="outlined"
        >
          <MinusIcon className="w-4 h-4 text-black-500" />
          Collapse
        </Button>
      )}
      <Dialog
        open={modalOpen}
        handler={() => setModalOpen(false)}
        className="max-w-full max-h-full overflow-y-auto"
      >
        <img
          src={currentImage}
          alt="Modal Content"
          className="max-w-full max-h-[90vh] object-contain"
        />
      </Dialog>
    </div>
  );
};

export default RoleThread;
