import { useContext, useState, useEffect } from "react";
import {
  Typography,
  Dialog,
  Spinner,
  Button,
  Avatar,
  Input,
  DialogHeader,
  DialogFooter,
  DialogBody,
} from "@material-tailwind/react";
import Layout from "../components/Layout";
import { AuthContext } from "../server/AuthContext";
import { ProfileSidebar } from "../components/ProfileSidebar";
import { CopyPasswordText } from "../components/CopyText";
import { userInfo, userKeys, deleteUserKey, createUserKey } from "../api/User";
import {
  TrashIcon,
  PlusIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";

const formatDate = (timestamp) => {
  if (!timestamp) {
    return "Unknown";
  }

  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

function GeneralContent({ email, username, subscription, memberSince }) {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-row">
        <Typography variant="h6">Email</Typography>
        <div className="ml-6">{email}</div>
      </div>
      <div className="flex flex-row">
        <Typography variant="h6">Username</Typography>
        <div className="ml-6">{username}</div>
      </div>
      <div className="flex flex-row">
        <Typography variant="h6">Member since</Typography>
        <div className="ml-6">{memberSince}</div>
      </div>
    </div>
  );
}

function APIKeysContent({ keysData, token, setUserKeysData }) {
  const [openCreate, setOpenCreate] = useState(null);
  const [newKeyName, setNewKeyName] = useState(null);

  const handleOpenCreate = () => setOpenCreate(!openCreate);
  const handleDeleteKey = async (key) => {
    try {
      await deleteUserKey(key);
      const data = await userKeys(token);
      console.log("got user keys:");
      console.log(data);
      setUserKeysData(data);
    } catch (error) {
      console.error("Error deleting key:", error);
    }
  };

  const handleCreateKey = async () => {
    try {
      await createUserKey(token, newKeyName);
      const data = await userKeys(token);
      console.log("got user keys:");
      console.log(data);
      setUserKeysData(data);
      handleOpenCreate();
    } catch (error) {
      console.error("Error creating key:", error);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <Dialog open={openCreate} handler={handleOpenCreate} size="sm">
        <DialogHeader>
          <PlusCircleIcon className="h-5 w-5 mr-4" />
          Create a new API key
        </DialogHeader>
        <DialogBody>
          <div className="flex flex-col gap-4 max-w-xl">
            <Input
              label="Key name"
              value={newKeyName}
              onChange={(e) => setNewKeyName(e.target.value)}
            />
            <Button
              onClick={handleCreateKey}
              className="flex flex-row gap-2 items-center justify-center mt-2"
              variant="outlined"
            >
              <PlusIcon className="h-4 w-4" />
              Create New Key
            </Button>
          </div>
        </DialogBody>
        <DialogFooter></DialogFooter>
      </Dialog>
      {keysData?.map((key) => (
        <div
          key={key.key}
          className="flex flex-col p-4 border border-solid rounded-2xl shadow-md bg-white"
        >
          <div className="flex flex-row items-center">
            <div className="mr-2 mt-2">
              <Typography variant="h6">{key.name}</Typography>
            </div>
            <div>
              <CopyPasswordText text={key.key} />
            </div>
            <Button
              color="red"
              size="sm"
              className="ml-4"
              variant="outlined"
              onClick={() => handleDeleteKey(key.key)}
            >
              <TrashIcon className="h-4 w-4" />
            </Button>
          </div>
          <div className="mt-1">
            <p className="text-sm">Created {formatDate(key.created)}</p>
          </div>
        </div>
      ))}
      <Button
        onClick={handleOpenCreate}
        className="flex flex-row gap-2 items-center justify-center mt-4 bg-white shadow-md"
        variant="outlined"
      >
        <PlusIcon className="h-4 w-4" />
        Create New Key
      </Button>
    </div>
  );
}

export default function ProfilePage() {
  const { currentUser, token } = useContext(AuthContext);
  console.log("current user");
  console.log(currentUser);

  const [selectedItem, setSelectedItem] = useState("General");
  const [userInfoData, setUserInfoData] = useState(null);
  const [userKeysData, setUserKeysData] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const data = await userInfo(token);
        console.log("got user info:");
        console.log(data);
        setUserInfoData(data);
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();

    const fetchUserKeys = async () => {
      try {
        const data = await userKeys(token);
        console.log("got user keys:");
        console.log(data);
        setUserKeysData(data);
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserKeys();
  }, [token]);

  return (
    <Layout>
      <div className="flex flex-row mt-12">
        <div className="ml-6">
          <ProfileSidebar
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        </div>
        <div className="ml-10 flex flex-col">
          <div className="flex flex-row">
            <div className="mr-6">
              <Avatar
                src={currentUser?.photoURL}
                alt="avatar"
                size="xxl"
                className="shadow-md border-solid border-1 border-gray-800"
              />
            </div>
            <div>
              <Typography
                color="gray"
                variant="h4"
                style={{ marginTop: "30px" }}
              >
                {currentUser?.displayName}
              </Typography>
            </div>
          </div>
          <div className="mt-10">
            {selectedItem === "General" && (
              <GeneralContent
                email={userInfoData?.email}
                username={userInfoData?.handle}
                subscription={userInfoData?.subscription || "Not subscribed"}
                memberSince={formatDate(userInfoData?.created)}
              />
            )}
            {selectedItem === "API Keys" && (
              <APIKeysContent
                keysData={userKeysData}
                token={token}
                setUserKeysData={setUserKeysData}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
