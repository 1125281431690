import { UnauthorizedError } from "./Error";
import { getAuthAPIAddr } from "./Env";

export async function userInfo(token) {
  const url = new URL("/v1/users/me", getAuthAPIAddr());
  console.log("getting user info from URL: ", url);

  console.log("getting user info with token: ", token);

  try {
    const resp = await fetch(url, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      redirect: "follow",
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log(resp);
    const data = await resp.json();
    console.log("got user data: ", data);

    return data;
  } catch (error) {
    console.error("Failed to fetch data", error);
  }
}

export async function userKeys(token) {
  const url = new URL("/v1/users/me/keys", getAuthAPIAddr());
  console.log("getting user keys from URL: ", url);

  console.log("getting user keys with token: ", token);

  try {
    const resp = await fetch(url, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      redirect: "follow",
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log(resp);
    const data = await resp.json();
    console.log("got user key data: ", data);

    return data.keys;
  } catch (error) {
    console.error("Failed to fetch data", error);
  }
}

export async function regenUserKeys(token) {
  const url = new URL("/v1/users/me/keys", getAuthAPIAddr());
  console.log("regenerating user keys from URL: ", url);
  console.log("regenerating user keys with token: ", token);

  try {
    const resp = await fetch(url, {
      method: "PUT",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      redirect: "follow",
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log(resp);
    const data = await resp.json();
    console.log("got new user key data: ", data);

    return data;
  } catch (error) {
    console.error("Failed to fetch data", error);
  }
}

export async function setUserInfo(token, handle) {
  const url = new URL("/v1/users/me", getAuthAPIAddr());
  console.log("setting user info from URL: ", url);
  console.log("setting user info with token: ", token);

  var bodyData = {
    handle: handle,
  };

  try {
    const resp = await fetch(url, {
      method: "PUT",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      redirect: "follow",
      body: JSON.stringify(bodyData),
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log(resp);
    const data = await resp.json();
    console.log("got new user key data: ", data);

    return data;
  } catch (error) {
    console.error("Failed to fetch data", error);
  }
}

export async function checkUserHandle(token, handle) {
  const baseURL = getAuthAPIAddr(); // Assuming getAPIAddr() returns the base URL of your API
  const url = new URL("/v1/check/handle", baseURL);
  url.searchParams.append("handle", handle); // Appending 'handle' as a query parameter

  console.log("Checking user handle from URL: ", url);

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token, // Assuming the token is passed for authorization
      },
    });

    if (response.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }

    if (!response.ok) {
      throw new Error("HTTP status " + response.status);
    }

    console.log("Response: ", response);
    const data = await response.json();
    console.log("Received data: ", data);

    return data.exists;
  } catch (error) {
    console.error("Failed to fetch data", error);
  }
}

export async function createUserKey(token, keyName) {
  const url = new URL("/v1/users/me/keys", getAuthAPIAddr());
  console.log("creating user key from URL: ", url);
  console.log("creating user key with token: ", token);

  var bodyData = {
    name: keyName,
  };
  try {
    const resp = await fetch(url, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      redirect: "follow",
      body: JSON.stringify(bodyData),
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log(resp);
    const data = await resp.json();
    console.log("created user key data: ", data);

    return data;
  } catch (error) {
    console.error("Failed to create key", error);
  }
}

export async function deleteUserKey(key) {
  const url = new URL(`/v1/users/me/keys`, getAuthAPIAddr());
  console.log("deleting user key from URL: ", url);
  console.log("deleting user key with token: ", key);

  try {
    const resp = await fetch(url, {
      method: "DELETE",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + key,
      },
      redirect: "follow",
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log(resp);
    console.log("deleted user key successfully");

    return;
  } catch (error) {
    console.error("Failed to delete key", error);
  }
}
