import { useEffect, useContext, useState } from "react";
import { AuthContext } from "../server/AuthContext";
import { Button, Typography } from "@material-tailwind/react";
import { getPrompts, approvePrompt, failPrompt } from "../api/Tasks";
import RoleThread from "./RoleThread";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

export default function Prompts({ taskId }) {
  const { token } = useContext(AuthContext);
  const [prompts, setPrompts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState({}); // State for expanded view per prompt
  const [filter, setFilter] = useState({
    approvalStatus: "any",
    namespace: "all",
    model: "all",
  });

  const handleApprove = async (id) => {
    console.log("approving prompt: ", id);
    approvePrompt(taskId, id, token);
  };

  const handleFail = async (id) => {
    console.log("failing prompt: ", id);
    failPrompt(taskId, id, token);
  };

  useEffect(() => {
    if (token !== undefined) {
      setLoading(true);
      getPrompts(taskId, token).then((data) => {
        setPrompts(data);
        setLoading(false);
      });

      const intervalId = setInterval(async () => {
        getPrompts(taskId, token).then((data) => {
          setPrompts(data);
          setLoading(false);
        });
      }, 2000);

      return () => clearInterval(intervalId);
    }
  }, [token, taskId]);

  const filteredPrompts = prompts.filter((prompt) => {
    const matchesApprovalStatus =
      filter.approvalStatus === "any" ||
      (filter.approvalStatus === "approved" && prompt.approved) ||
      (filter.approvalStatus === "notApproved" && !prompt.approved);
    const matchesNamespace =
      filter.namespace === "all" || filter.namespace === prompt.namespace;
    const matchesModel =
      filter.model === "all" || filter.model === prompt.model;

    return matchesApprovalStatus && matchesNamespace && matchesModel;
  });

  const toggleExpanded = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <div className="flex flex-row gap-2">
      <div className="flex flex-col gap-6 mb-4 mt-10">
        <FormControl
          variant="outlined"
          className="w-full"
          style={{ minWidth: 200 }}
          size="small"
        >
          <InputLabel>Approval Status</InputLabel>
          <Select
            value={filter.approvalStatus}
            onChange={(e) =>
              setFilter({ ...filter, approvalStatus: e.target.value })
            }
            label="Approval Status"
          >
            <MenuItem value="any">Any Approval</MenuItem>
            <MenuItem value="approved">Approved</MenuItem>
            <MenuItem value="notApproved">Not Approved</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          variant="outlined"
          className="w-full"
          size="small"
          style={{ minWidth: 200 }}
        >
          <InputLabel>Namespace</InputLabel>
          <Select
            value={filter.namespace}
            onChange={(e) =>
              setFilter({ ...filter, namespace: e.target.value })
            }
            label="Namespace"
          >
            <MenuItem value="all">All Namespaces</MenuItem>
            {Array.from(new Set(prompts.map((prompt) => prompt.namespace))).map(
              (namespace) => (
                <MenuItem key={namespace} value={namespace}>
                  {namespace}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>

        <FormControl
          variant="outlined"
          className="w-full"
          size="small"
          style={{ minWidth: 200 }}
        >
          <InputLabel>Model</InputLabel>
          <Select
            value={filter.model}
            onChange={(e) => setFilter({ ...filter, model: e.target.value })}
            label="Model"
          >
            <MenuItem value="all">All Models</MenuItem>
            {Array.from(new Set(prompts.map((prompt) => prompt.model))).map(
              (model) => (
                <MenuItem key={model} value={model}>
                  {model}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
        <Button
          color="green"
          className="mt-6"
          variant="outlined"
          onClick={() => handleApprove("all")}
        >
          Approve All
        </Button>
      </div>

      <div className="flex flex-col max-h-[600px] overflow-y-scroll">
        {filteredPrompts &&
          filteredPrompts.length > 0 &&
          filteredPrompts.map((prompt) => (
            <div
              key={prompt.id}
              className="flex flex-col border border-gray-300 shadow-md rounded-lg p-4 m-4 w-full gap-4"
            >
              <div className="flex flex-row w-full justify-between">
                <div className="flex flex-col">
                  <Typography variant="h6">ID</Typography>
                  <Typography variant="paragraph">{prompt.id}</Typography>
                </div>
                <div className="mr-4">
                  {prompt.approved ? (
                    <Button
                      size="sm"
                      color="red"
                      onClick={() => handleFail(prompt.id)}
                    >
                      Fail
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      color="green"
                      onClick={() => handleApprove(prompt.id)}
                    >
                      Approve
                    </Button>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <Typography variant="h6">Thread</Typography>
                <div className="relative w-full">
                  <RoleThread
                    data={prompt.thread}
                    expanded={expanded[prompt.id] || false}
                    toggleExpanded={() => toggleExpanded(prompt.id)}
                    isExpandable={true} // Pass true to make it expandable
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <Typography variant="h6">Response</Typography>
                <Typography variant="paragraph">
                  {prompt.response.text}
                </Typography>
              </div>
              <div className="flex flex-col">
                <Typography variant="h6">Approved</Typography>
                <Typography variant="paragraph">
                  {prompt.approved ? "Yes" : "No"}
                </Typography>
              </div>
              <div className="flex flex-col">
                <Typography variant="h6">Namespace</Typography>
                <Typography variant="paragraph">
                  {prompt.namespace ? prompt.namespace : "NA"}
                </Typography>
              </div>
              <div className="flex flex-col">
                <Typography variant="h6">Model</Typography>
                <Typography variant="paragraph">
                  {prompt.model ? prompt.model : "NA"}
                </Typography>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
