import {
  Typography,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Card,
  CardHeader,
  CardBody,
  Button,
  Chip,
} from "@material-tailwind/react";
import { useState, useEffect, useMemo } from "react";
import Layout from "../components/Layout";
import { useContext } from "react";
import { AuthContext } from "../server/AuthContext";
import { listAgents } from "../api/Agents";
import { getAgentTypes } from "../api/Types";
import { Input } from "@material-tailwind/react";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import RegisterAgentModal from "../components/RegisterAgentModal";
import PlusButtonText from "../components/PlusButtonText";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import { Spinner } from "@material-tailwind/react";

export default function AgentsPage() {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);

  console.log("got token from auth context: ", token);
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);

  const [openRegister, setOpenRegister] = useState(false);
  const handleOpenRegister = () => setOpenRegister(!openRegister);
  const handleCloseRegister = () => setOpenRegister(false);

  const handleAgentClick = (agentObj) => {
    navigate("/agents/" + agentObj.name);
  };

  const showCreateAgentButtonBig = useMemo(() => {
    // Check if there are no tasks
    if (agents && agents.length > 0) {
      return false;
    }
    return true;
  }, [agents]);

  useEffect(() => {
    if (token !== undefined) {
      setLoading(true);
      getAgentTypes(token).then((data) => {
        setAgents(data);
        setLoading(false);
      });
      // Then set the interval
      const intervalId = setInterval(async () => {
        var agnts = await getAgentTypes(token);
        console.log("setting agnts: ");
        console.log(agnts);
        setAgents(agnts);
        setLoading(false);
      }, 2000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [token]);

  useEffect(() => {
    console.log("!!!agents:");
    console.log(agents);
  }, [agents]);

  if (loading) {
    // If the component is still loading, show a spinner
    return (
      <Layout>
        <div className="flex justify-center items-center mt-72">
          <Spinner className="h-24 w-24" />
        </div>
      </Layout>
    );
  }

  return (
    <>
      <Dialog open={openRegister} handler={handleOpenRegister}>
        <DialogHeader>
          <PlusCircleIcon className="h-5 w-5 mr-4" />
          Create an Agent
        </DialogHeader>
        <DialogBody>
          <RegisterAgentModal onClose={handleCloseRegister} />
          {/* <Typography className="text-2xl font-bold mb-4">Enter the URL of the agent</Typography>
                <Input className="" label="Agent URL" type="text"/> */}
        </DialogBody>
        <DialogFooter></DialogFooter>
      </Dialog>
      <Layout>
        <div className="flex flex-row mt-10">
          <div className="ml-1">{/* <AgentsSidebar /> */}</div>
          <div className="flex flex-col">
            <div className="mx-auto flex flex-wrap p-16">
              <motion.div
                className="mt-16 mr-16 mb-8"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                whileHover={{ scale: 1.05 }}
              >
                <PlusButtonText text="Create" onClick={null} />
              </motion.div>
              {agents &&
                agents.map((agentObj, key) => (
                  <motion.div
                    className="mb-8 flex flex-col ml-16"
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                    whileHover={{ scale: 1.05 }}
                  >
                    <div className="bg-white rounded-lg shadow-lg p-6 cursor-pointer flex flex-col w-72">
                      <img
                        className="w-64 h-36 object-cover bg-white rounded-lg"
                        src={agentObj.icon}
                        alt={agentObj.name}
                      />
                      <Typography variant="h4" className="mt-4">
                        {agentObj.namespace}/{agentObj.name}
                      </Typography>
                      <div className="flex flex-row max-w-36">
                        <Typography variant="small mt-2">
                          {agentObj.description}
                        </Typography>
                      </div>
                      <div className="flex flex-row mt-2">
                        {agentObj.tags &&
                          agentObj.tags.map((tag, key) => (
                            <Chip
                              color="gray"
                              size="md"
                              key={key}
                              value={tag}
                            ></Chip>
                          ))}
                      </div>
                    </div>
                    {/* <Card
                      className="mt-6 mb-8 ml-8 w-64 h-48 cursor-pointer"
                      key={key}
                      onClick={() => handleAgentClick(agentObj)}
                    >
                      <CardHeader color="blue-gray" className="relative h-56">
                        <img
                          className="w-64 h-36 object-cover bg-white"
                          src={agentObj.icon}
                          alt={agentObj.name}
                        />
                      </CardHeader>
                      <CardBody>
                        <div className="flex flex-col">
                          <Typography
                            variant="h5"
                            color="blue-gray"
                            className="mb-2 font-tifax"
                          >
                            {agentObj.name}
                          </Typography>
                          <Typography
                            variant="lead"
                            color="blue-gray"
                            className="mb-2"
                          >
                            {agentObj.status}
                          </Typography>
                        </div>
                      </CardBody>
                    </Card> */}
                  </motion.div>
                ))}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
