import { UnauthorizedError } from "./Error";
import { getAPIAddr } from "./Env";

export async function listTools(token) {
  const url = new URL("/v1/tools", getAPIAddr());
  console.log("listing tools from URL: ", url);

  console.log("calling tools with token: ", token);

  try {
    const resp = await fetch(url, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      redirect: "follow",
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log(resp);
    const data = await resp.json();
    console.log("got list tool data: ", data);
    var tools = data.tools;

    return tools;
  } catch (error) {
    console.error("Failed to fetch data", error);
  }
}

export async function registerTool(name, description, toolURL, iconURL, token) {
  const url = new URL("/v1/tools", getAPIAddr());
  console.log("registering tool from URL: ", url);

  console.log("calling tools with token: ", token);

  var bodyData = {
    name: name,
    description: description,
    url: toolURL,
    icon_url: iconURL,
  };

  try {
    const resp = await fetch(url, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      redirect: "follow",
      body: JSON.stringify(bodyData),
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log(resp);
    const tool = await resp.json();
    console.log("got registered tool: ", tool);

    return tool;
  } catch (error) {
    console.error("Failed to fetch data", error);
  }
}
