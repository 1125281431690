import {
  Typography,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { useContext } from "react";
import { AuthContext } from "../server/AuthContext";
import { listTools } from "../api/Tools";
import { AgentCard } from "../components/AgentCard";
import { AgentsSidebar } from "../components/AgentsSidebar";
import { Input } from "@material-tailwind/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import RegisterAppModal from "../components/RegisterAppModal";
import PlusButtonText from "../components/PlusButtonText";

export default function AppsPage() {
  const { token } = useContext(AuthContext);
  console.log("got token from auth context: ", token);
  const [apps, setApps] = useState([]);

  const [openRegister, setOpenRegister] = useState(false);
  const handleOpenRegister = () => setOpenRegister(!openRegister);
  const handleCloseRegister = () => setOpenRegister(false);

  useEffect(() => {
    if (token !== undefined) {
      listTools(token).then((data) => setApps(data));
      // Then set the interval
      const intervalId = setInterval(async () => {
        // var agnts = await listTools(token);
        // console.log("setting agnts: ");
        // console.log(agnts);
        setApps([]);
      }, 2000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [token]);

  useEffect(() => {
    console.log("!!!apps:");
    console.log(apps);
  }, [apps]);

  return (
    <>
      <Dialog open={openRegister} handler={handleOpenRegister}>
        <DialogHeader>
          <PencilSquareIcon className="h-5 w-5 mr-4" />
          Register
        </DialogHeader>
        <DialogBody>
          <RegisterAppModal onClose={handleCloseRegister} />
          {/* <Typography className="text-2xl font-bold mb-4">Enter the URL of the agent</Typography>
                  <Input className="" label="Agent URL" type="text"/> */}
        </DialogBody>
        <DialogFooter></DialogFooter>
      </Dialog>
      <Layout>
        <div className="flex flex-row mt-10">
          <div className="ml-6">
            <AgentsSidebar />
          </div>
          <div className="flex flex-col">
            <div className="pl-16 flex flex-row gap-6 items-center">
              <Typography className="text-2xl font-bold">Apps</Typography>
              <Typography className="text-2xl font-bold text-gray-700">
                {apps?.length}
              </Typography>
              <div className="w-72">
                <Input label="Search" />
              </div>
            </div>
            <div className="mx-auto flex flex-wrap p-16">
              <PlusButtonText text="Register" onClick={handleOpenRegister} />
              {apps?.map((agentObj, key) => (
                <AgentCard
                  name={agentObj.name}
                  imgURL={agentObj.icon_url}
                  description={agentObj.description}
                  key={key}
                />
              ))}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
