import React from "react";
import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import {
  UserCircleIcon,
  CodeBracketSquareIcon,
  DocumentMagnifyingGlassIcon,
  LifebuoyIcon,
  CubeTransparentIcon,
  WrenchScrewdriverIcon,
  PlayCircleIcon,
  BeakerIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/24/solid";
import {
  ChevronDownIcon,
  ClipboardDocumentIcon,
  ClipboardIcon,
  CloudIcon,
  ComputerDesktopIcon,
} from "@heroicons/react/24/outline";

// const CUSTOM_ANIMATION = {
//   initial: false,
//   mount: { y: 0, x: 0 },
//   unmount: { y: 0, x: 0 },
// };

const ANIMATION = {
  initial: false,
  mount: false, // { transition: { duration: 0.2 } },
  unmount: false, //{ transition: { duration: 0.2 } },
};

export function DocsSidebar() {
  const location = useLocation();
  const [open, setOpen] = useState(null);

  useEffect(() => {
    const section = location.pathname.startsWith("/docs/apps")
      ? "apps"
      : location.pathname.startsWith("/docs/agents")
      ? "agents"
      : null;
    setOpen(section);
  }, [location.pathname]);

  return (
    <Card className="h-screen w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5 pr-12 pl-8">
      <div className="mb-2 p-4">
        <Typography variant="h5" color="blue-gray">
          Content
        </Typography>
      </div>
      <List>
        <NavLink to="/docs/overview">
          <ListItem
            selected={
              location.pathname === "/docs/overview" ||
              location.pathname === "/docs"
            }
          >
            <ListItemPrefix>
              <DocumentMagnifyingGlassIcon className="h-5 w-5" />
            </ListItemPrefix>
            Overview
          </ListItem>
        </NavLink>
        <NavLink to="/docs/agents">
          <ListItem selected={location.pathname === "/docs/agents"}>
            <ListItemPrefix>
              <UserCircleIcon className="h-5 w-5" />
            </ListItemPrefix>
            Agents
          </ListItem>
        </NavLink>
        <NavLink to="/docs/devices">
          <ListItem selected={location.pathname === "/docs/devices"}>
            <ListItemPrefix>
              <ComputerDesktopIcon className="h-5 w-5" />
            </ListItemPrefix>
            Devices
          </ListItem>
        </NavLink>
        <NavLink to="/docs/tasks">
          <ListItem selected={location.pathname === "/docs/tasks"}>
            <ListItemPrefix>
              <ClipboardIcon className="h-5 w-5" />
            </ListItemPrefix>
            Tasks
          </ListItem>
        </NavLink>
        <NavLink to="/docs/benchmarks">
          <ListItem selected={location.pathname === "/docs/benchmarks"}>
            <ListItemPrefix>
              <PresentationChartLineIcon className="h-5 w-5" />
            </ListItemPrefix>
            Boards
          </ListItem>
        </NavLink>
      </List>
    </Card>
  );
}
