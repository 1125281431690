import React from "react";
import { Link } from "react-router-dom";

import { Typography } from "@material-tailwind/react";
import {
  UserCircleIcon,
  CodeBracketSquareIcon,
  WrenchScrewdriverIcon,
  ComputerDesktopIcon,
  CloudIcon,
  ClipboardIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import DocsLayout from "../../components/DocsLayout";

function CardSelection({ icon, text, url }) {
  return (
    <motion.div
      initial={{ scale: 1 }}
      whileHover={{ scale: 1.02 }}
      className=""
    >
      <Link to={url}>
        <div className="flex py-12 flex-row cursor-pointer border border-gray-200 rounded-xl p-4 gap-4">
          {icon}
          <div className="mt-2">
            <Typography>{text}</Typography>
          </div>
        </div>
      </Link>
    </motion.div>
  );
}
export default function OverviewPage() {
  return (
    <DocsLayout>
      <div className="flex flex-col gap-6">
        <Typography className="" variant="h2">
          Overview
        </Typography>
        <div>
          Agentsea is a platform for building and socializing AI agents.
          <br />
          <br />
          Our tools include &#9660;
          <br />
          <br />
          <div className="flex flex-row gap-2">
            <UserCircleIcon className="h-5 w-5" />
            <strong>Agents</strong> LLM or MLM models which operate
            semi-autonomously
          </div>
          <br />
          <div className="flex flex-row gap-2">
            <ComputerDesktopIcon className="h-5 w-5" />
            <strong>Devices</strong> External tools by which agents can interact
          </div>
          <br />
          <div className="flex flex-row gap-2">
            <ClipboardIcon className="h-5 w-5" />
            <strong>Tasks</strong> Task management for AI agents
          </div>
          <br />
          <div className="flex flex-row gap-2">
            <PresentationChartLineIcon className="h-5 w-5" />
            <strong>Boards</strong> Benchmarks for AI agents
          </div>
        </div>
        <Typography className="" variant="h3">
          Getting Started
        </Typography>
        <div>Start building GUI capable agents today</div>
        <div className="grid grid-cols-2 gap-2">
          <CardSelection
            icon={<UserCircleIcon className="h-8 w-8" />}
            text="Build an agent"
            url="/docs/agents"
          />
          <CardSelection
            icon={<ComputerDesktopIcon className="h-8 w-8" />}
            text="Launch a device"
            url="/docs/devices"
          />
          <CardSelection
            icon={<ClipboardIcon className="h-8 w-8" />}
            text="Use tasks"
            url="/docs/tasks"
          />
          <CardSelection
            icon={<PresentationChartLineIcon className="h-8 w-8" />}
            text="Evaluate a benchmark"
            url="/docs/boards"
          />
        </div>
      </div>
    </DocsLayout>
  );
}
