// Imports and utility functions as needed
import { UnauthorizedError } from "./Error";
import { getAPIAddr } from "./Env";

// List all jobs for the current user
export async function listJobs(token) {
  const url = new URL("/v1/jobs", getAPIAddr());
  console.log("Retrieving jobs from URL: ", url);

  try {
    const response = await fetch(url, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!response.ok) {
      throw new Error(`HTTP status ${response.status}`);
    }
    const jobs = await response.json();
    console.log("Retrieved jobs: ", jobs);
    return jobs;
  } catch (error) {
    console.error("Failed to retrieve jobs", error);
    throw error;
  }
}

// Get a specific job by ID for the current user
export async function getJobById(jobId, token) {
  const url = new URL(`/v1/jobs/${jobId}`, getAPIAddr());
  console.log("Retrieving job from URL: ", url);

  try {
    const response = await fetch(url, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!response.ok) {
      throw new Error(`HTTP status ${response.status}`);
    }
    const job = await response.json();
    console.log("Retrieved job: ", job);
    return job;
  } catch (error) {
    console.error("Failed to retrieve job", error);
    throw error;
  }
}
