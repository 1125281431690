import { UnauthorizedError } from "./Error";
import { getAPIAddr } from "./Env";

// Create an agent type
export async function createAgentType(data, token) {
  const url = new URL("/v1/agenttypes", getAPIAddr());
  console.log("Creating agent type with URL: ", url);

  try {
    const resp = await fetch(url, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    const data = await resp.json();
    console.log("Agent type created: ", data);
    return data;
  } catch (error) {
    console.error("Failed to create agent type", error);
  }
}

// Get all agent types for the current user
export async function getAgentTypes(token) {
  const url = new URL("/v1/agenttypes", getAPIAddr());
  console.log("Retrieving agent types from URL: ", url);

  try {
    const resp = await fetch(url, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    const data = await resp.json();
    console.log("Retrieved agent types: ", data);
    return data.types;
  } catch (error) {
    console.error("Failed to retrieve agent types", error);
  }
}

// Get a specific agent type by name
export async function getAgentTypeByName(name, token) {
  const url = new URL(`/v1/agenttypes/${name}`, getAPIAddr());
  console.log("Retrieving agent type from URL: ", url);

  try {
    const resp = await fetch(url, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    const data = await resp.json();
    console.log("Retrieved agent type: ", data);
    return data;
  } catch (error) {
    console.error("Failed to retrieve agent type", error);
  }
}

// Delete an agent type by name
export async function deleteAgentTypeByName(name, token) {
  const url = new URL(`/v1/agenttypes/${name}`, getAPIAddr());
  console.log("Deleting agent type with URL: ", url);

  try {
    const resp = await fetch(url, {
      method: "DELETE",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log("Agent type deleted successfully");
    return true;
  } catch (error) {
    console.error("Failed to delete agent type", error);
    return false;
  }
}
