import { Typography } from "@material-tailwind/react"
import Layout from "../components/Layout"


export default function NotFoundPage() {

    return (
        <Layout>
            <div className="mx-auto max-w-screen-sm rounded-xl border border-grey-700 flex flex-col text-center shadow-2xl justify-center p-16 mt-36">

                <Typography className="mb-4 text-5xl font-bold">Not Found</Typography>

            </div>
        </Layout>
    )
}