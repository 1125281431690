import { UnauthorizedError } from "./Error";
import { getAPIAddr } from "./Env";

export async function listAgents(token) {
  const url = new URL("/v1/agents", getAPIAddr());
  console.log("listing agents from URL: ", url);

  console.log("calling agents with token: ", token);

  try {
    const resp = await fetch(url, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      redirect: "follow",
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log(resp);
    const data = await resp.json();
    console.log("got list agents data: ", data);
    var agents = data.agents;

    return agents;
  } catch (error) {
    console.error("Failed to fetch data", error);
  }
}

// class CreateAgentModel(BaseModel):
//     name: str
//     runtime: str
//     type: str
//     desktop: Optional[str] = None
//     desktop_request: Optional[CreateDesktopModel] = None
//     metadata: dict = {}
//     envs: dict = {}
//     secrets: dict = {}
//     wait_ready: bool = True

export async function createAgent(
  name,
  runtime,
  type,
  desktop_request,
  metadata,
  envs,
  secrets,
  waitReady,
  version,
  token
) {
  const url = new URL("/v1/agents", getAPIAddr());
  console.log("registering agent from URL: ", url);

  console.log("calling agents with token: ", token);

  var bodyData = {
    name: name,
    runtime: runtime,
    type: type,
    desktop_request: desktop_request,
    metadata: metadata,
    envs: envs,
    secrets: secrets,
    version: version,
    waitReady: waitReady,
  };

  try {
    const resp = await fetch(url, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      redirect: "follow",
      body: JSON.stringify(bodyData),
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log(resp);
    const agent = await resp.json();
    console.log("got registered agent: ", agent);

    return agent;
  } catch (error) {
    console.error("Failed to fetch data", error);
  }
}

export async function getAgent(name, token) {
  const url = new URL("/v1/agents/" + name, getAPIAddr());
  console.log("getting agent from URL: ", url);

  console.log("calling agents with token: ", token);

  try {
    const resp = await fetch(url, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      redirect: "follow",
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log(resp);
    const data = await resp.json();
    console.log("got agent data: ", data);

    return data;
  } catch (error) {
    console.error("Failed to fetch data", error);
  }
}

export async function deleteAgent(name, token) {
  const url = new URL(`/v1/agents/${name}`, getAPIAddr());
  console.log("deleting agent from URL: ", url);

  console.log("calling delete agent with token: ", token);

  try {
    const resp = await fetch(url, {
      method: "DELETE",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      redirect: "follow",
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log("Agent deleted successfully");
  } catch (error) {
    console.error("Failed to delete agent", error);
  }
}

export async function startAgent(name, token) {
  const url = new URL(`/v1/agents/${name}/start`, getAPIAddr());
  console.log("starting agent with URL: ", url);

  console.log("calling start agent with token: ", token);

  try {
    const resp = await fetch(url, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      redirect: "follow",
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log("Agent started successfully");
    return true;
  } catch (error) {
    console.error("Failed to start agent", error);
    return false;
  }
}

export async function stopAgent(name, token) {
  const url = new URL(`/v1/agents/${name}/stop`, getAPIAddr());
  console.log("stopping agent with URL: ", url);

  console.log("calling stop agent with token: ", token);

  try {
    const resp = await fetch(url, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      redirect: "follow",
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log("Agent stopped successfully");
    return true;
  } catch (error) {
    console.error("Failed to stop agent", error);
    return false;
  }
}

export async function solveTask(name, task, maxSteps, site, device, token) {
  const url = new URL(`/v1/agents/${name}/tasks`, getAPIAddr());
  console.log("solving task for agent with URL: ", url);

  console.log("calling solve task with token: ", token);

  if (site) {
    task["parameters"] = { site: site };
  }
  const bodyData = {
    task: task,
    max_steps: maxSteps,
    device: device,
  };

  console.log("## solving task with body: ");
  console.log(bodyData);

  try {
    const resp = await fetch(url, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      redirect: "follow",
      body: JSON.stringify(bodyData),
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log("Task started successfully");
    const data = await resp.json();
    console.log("Got task solve response: ", data);
    return data;
  } catch (error) {
    console.error("Failed to solve task", error);
  }
}

export async function getAgentTasks(name, token) {
  const url = new URL(`/v1/agents/${name}/tasks`, getAPIAddr());
  console.log("listing agent tasks with URL: ", url);

  console.log("listing agent tasks with token: ", token);

  try {
    const resp = await fetch(url, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      redirect: "follow",
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log("Listed tasks successfully");
    const data = await resp.json();
    console.log("Got list tasks response: ", data);
    return data.tasks;
  } catch (error) {
    console.error("Failed to solve task", error);
  }
}

export async function fetchAgentLogs(agentName, token, tailLines = 100) {
  const url = new URL(
    `/v1/agents/${agentName}/logs?tail_lines=${tailLines}`,
    getAPIAddr()
  );
  console.log("Fetching logs for agent with URL: ", url);

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }

    if (!response.ok) {
      throw new Error(`HTTP status ${response.status}`);
    }

    const logsJson = await response.json(); // Assuming the logs are returned as plain text
    console.log("Fetched logs successfully for agent: ", agentName);
    return logsJson.logs;
  } catch (error) {
    console.error("Failed to fetch agent logs", error);
    throw error; // Rethrow to handle it in the calling component
  }
}

export async function fetchLLMProviders(agentType, token) {
  const url = new URL(`/v1/llmproviders/${agentType}`, getAPIAddr());
  console.log("Fetching logs for agent with URL: ", url);

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }

    if (!response.ok) {
      throw new Error(`HTTP status ${response.status}`);
    }

    const providers = await response.json(); // Assuming the logs are returned as plain text
    console.log("Fetched llm providers successfully for agent: ", providers);
    return providers.options;
  } catch (error) {
    console.error("Failed to fetch agent logs", error);
    throw error; // Rethrow to handle it in the calling component
  }
}
