import { PlusIcon } from "@heroicons/react/24/solid";
import { Typography } from "@material-tailwind/react";
import { motion } from "framer-motion";

export default function PlusButtonText({ text, onClick }) {
  return (
    <div className="items-center justify-center text-center">
      <motion.div initial={{ scale: 1 }} whileHover={{ scale: 1.02 }}>
        <div
          className="bg-white rounded-full h-24 w-24 flex justify-center items-center cursor-pointer border border-black-900 shadow-md"
          onClick={onClick}
        >
          <PlusIcon className="h-12 w-12 text-black" />
        </div>
      </motion.div>
      <Typography className="mt-2" variant="h6">
        {text}
      </Typography>
    </div>
  );
}
