import Footer from "./Footer";
import { Nav } from "./Nav";

const Layout = ({ children }) => {
  return (
    <>
      <Nav />
      <div className="flex flex-col min-h-screen max-h-screen max-w-screen-xl mx-auto">
        <main className="flex-grow">{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
