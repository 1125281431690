import Footer from "./Footer";
import { LandingNav } from "./LandingNav";

const LandingLayout = ({ children }) => {
    return (
        <>
            <LandingNav />
            <div className="flex flex-col min-h-screen">
                <main className="flex-grow">
                    {children}
                </main>
                <Footer />
            </div>
        </>
    );
};

export default LandingLayout;