import {
  Typography,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Spinner,
  Textarea,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Chip,
} from "@material-tailwind/react";
import { useState, useEffect, useRef, useMemo, createElement } from "react";
import Layout from "../components/Layout";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../server/AuthContext";
import {
  getAgent,
  deleteAgent,
  stopAgent,
  startAgent,
  fetchAgentLogs,
} from "../api/Agents";
import { getJobById } from "../api/Job";
import { Input } from "@material-tailwind/react";
import {
  PencilSquareIcon,
  PlusIcon,
  PlusCircleIcon,
  ExclamationTriangleIcon,
  ClipboardIcon,
  StopCircleIcon,
  XCircleIcon,
  Square3Stack3DIcon,
  UserCircleIcon,
  Cog6ToothIcon,
  ComputerDesktopIcon,
  TableCellsIcon,
  CodeBracketIcon,
  PlayCircleIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import RegisterAgentModal from "../components/RegisterAgentModal";
import PlusButtonText from "../components/PlusButtonText";
import { Avatar } from "@material-tailwind/react";
import { VncScreen } from "react-vnc";
import { getAPIAddr } from "../api/Env";
import { solveTask, getAgentTasks } from "../api/Agents";
import Task from "../components/Task";
import { motion } from "framer-motion";

// // Define a wrapper component for VncScreen
// const VncScreenLogger = ({ url }) => {
//   const ref = useRef(null); // Assuming you want to use ref inside VncScreen

//   useEffect(() => {
//     // Log a message every time the component mounts
//     console.log(
//       "CHANGE VncScreen component has re-rendered/mounted with URL:",
//       url
//     );
//   }, [url]); // Empty dependency array means this effect runs only on mount

//   return (
//     <VncScreen
//       url={url}
//       scaleViewport
//       background="#000000"
//       style={{ width: "900px", height: "720px" }}
//       rfbOptions={{ credentials: { password: "agentsea123" }, shared: true }}
//       ref={ref}
//       debug={true}
//       showDotCursor={true}
//       onConnect={(rfb) => {
//         console.log(`CHANGE Connected to VNC server at timestamp:`, Date.now());
//         console.log(rfb);
//       }}
//       onDisconnect={(rfb) => {
//         console.log(
//           `CHANGE Disconnected from VNC server at timestamp:`,
//           Date.now()
//         );
//         console.log(rfb);
//       }}
//       onSecurityFailure={(e) =>
//         console.log(
//           `CHANGE Security failure: ${e.detail} at timestamp:`,
//           Date.now()
//         )
//       }
//       //   loadingUI={<div>Connecting to VNC server...</div>}
//     />
//   );
// };

export default function AgentPage() {
  const navigate = useNavigate();
  const { name } = useParams();
  const { token } = useContext(AuthContext);
  console.log("got token from auth context: ", token);
  const [agent, setAgent] = useState(null);
  const [job, setJob] = useState(null);
  const [description, setDescription] = useState(null);
  const [maxSteps, setMaxSteps] = useState("20");
  const [startingURL, setStartingURL] = useState(null);
  const [agentTasks, setAgentTasks] = useState(null);
  const [selectedIcon, setSelectedIcon] = useState("clipboard");
  const [logs, setLogs] = useState("");
  const [isUserAtBottom, setIsUserAtBottom] = useState(true);

  const ref = useRef();
  const ws = useRef(null);

  const [openStopConfirm, setOpenStopConfirm] = useState(false);
  const handleOpenStopConfirm = () => setOpenStopConfirm(!openStopConfirm);
  const handleCloseStopConfirm = () => setOpenStopConfirm(false);

  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const handleOpenDeleteConfirm = () =>
    setOpenDeleteConfirm(!openDeleteConfirm);
  const handleCloseDeleteConfirm = () => setOpenDeleteConfirm(false);

  const [openTaskModal, setOpenTaskModal] = useState(false);
  const handleOpenTaskModal = () => setOpenTaskModal(!openTaskModal);
  const handleCloseTaskModal = () => setOpenTaskModal(false);

  const handleCreateTask = () => {
    setOpenTaskModal(true);
  };

  // useEffect(() => {
  //   // Only attempt to connect if the agent is running and the "Code" tab is selected
  //   if (agent && agent.status === "running" && selectedIcon === "code") {
  //     const wsUrl = convertHttpsToWss(
  //       getAPIAddr() + "/ws/agents/" + name + "/logs?token=" + token
  //     );
  //     ws.current = new WebSocket(wsUrl);

  //     ws.current.onopen = () => {
  //       console.log("WebSocket Connected");
  //     };

  //     ws.current.onmessage = (e) => {
  //       console.log("WebSocket Message: ", e.data);
  //       const message = e.data;
  //       setLogs((prevLogs) => prevLogs + "\n" + message);
  //     };

  //     ws.current.onerror = (error) => {
  //       console.error("WebSocket Error: ", error);
  //     };

  //     ws.current.onclose = () => {
  //       console.log("WebSocket Disconnected");
  //     };

  //     return () => {
  //       ws.current.close();
  //     };
  //   }
  // }, [agent, selectedIcon, name, token]);

  const logsContainerRef = useRef(null);

  useEffect(() => {
    let intervalId;

    if (agent && agent.status === "running" && selectedIcon === "code") {
      const fetchAndSetLogs = () => {
        fetchAgentLogs(name, token, 1000).then((logs) => {
          setLogs(logs);
          if (isUserAtBottom) {
            scrollToBottom(); // Ensure the logs container scrolls to bottom after fetching
          }
        });
      };

      // Immediately invoke fetchAndSetLogs to ensure logs are fetched right away
      fetchAndSetLogs();

      // Then set up the interval to repeatedly fetch logs
      intervalId = setInterval(fetchAndSetLogs, 2000);
    }

    // Cleanup function to clear the interval when the component unmounts or conditions change
    return () => clearInterval(intervalId);
  }, [agent, selectedIcon, name, token]); // Dependencies list

  // Define the scrollToBottom function at the top level of your component
  const scrollToBottom = () => {
    if (isUserAtBottom && logsContainerRef.current) {
      const { scrollHeight } = logsContainerRef.current;
      logsContainerRef.current.scrollTop = scrollHeight;
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (!logsContainerRef.current) return;

      const { scrollTop, clientHeight, scrollHeight } =
        logsContainerRef.current;
      const threshold = 10; // Adjust as necessary
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - threshold;
      setIsUserAtBottom(isAtBottom);

      console.log(`Detected scroll. Is at bottom: ${isAtBottom}`);
    };

    const currentContainer = logsContainerRef.current;
    if (currentContainer) {
      currentContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [logs]);

  const [activeTab, setActiveTab] = useState("html");
  const data = [
    {
      label: "Desktop",
      value: "desktop",
      icon: ComputerDesktopIcon,
      desc: `It really matters and then like it really doesn't matter.
      What matters is the people who are sparked by it. And the people
      who are like offended by it, it doesn't matter.`,
    },
    {
      label: "Spreadsheet",
      value: "spreadsheet",
      icon: TableCellsIcon,
      desc: `Because it's about motivating the doers. Because I'm here
      to follow my dreams and inspire other people to follow their dreams, too.`,
    },
    {
      label: "Git Repo",
      value: "git",
      icon: CodeBracketIcon,
      desc: `Because it's about motivating the doers. Because I'm here
        to follow my dreams and inspire other people to follow their dreams, too.`,
    },
  ];

  const showCreateTaskButtonBig = useMemo(() => {
    // Check if there are no tasks
    if (agentTasks && agentTasks.length > 0) {
      return false;
    }

    if (!agent) {
      return false;
    }

    if (agent.status !== "running") {
      return false;
    }

    return true;
  }, [agentTasks, agent]);

  const showCreateTaskButtonSmall = useMemo(() => {
    // Check if the agentTasks array is empty or does not exist
    if (!agentTasks || agentTasks.length === 0) {
      return true;
    }

    // Get the status of the first task
    const firstTaskStatus = agentTasks[0].status;

    // Define the statuses to check against
    const validStatuses = ["running", "defined", "created", "in progress"];

    // Check if the first task's status is not one of the valid statuses
    if (!validStatuses.includes(firstTaskStatus)) {
      return true;
    }

    // If none of the above conditions are met, we do not show the button
    return false;
  }, [agentTasks]);

  const showLoadingAgentVid = useMemo(() => {
    return false;
    // var show = false;
    // if (agentTasks && agentTasks.length > 0) {
    //   return false;
    // }

    // if (!agent) {
    //   return true;
    // }

    // if (agent.status !== "running") {
    //   show = true;
    // }

    // return show;
  }, [agentTasks, agent]);

  const handleSubmitTask = () => {
    var task = {
      description: description,
      assigned_to: name,
    };
    solveTask(
      name,
      task,
      parseInt(maxSteps),
      startingURL,
      agent.desktop,
      token
    ).then((data) => {
      console.log("task assigned to agent");
      setOpenTaskModal(false);
      setMaxSteps("20"); // Consider resetting to the default value instead of an empty string if applicable
      setStartingURL(null); // Use null to indicate the absence of a value
      setDescription(null); // Use null to indicate the absence of a value
    });
  };

  const handleStopClicked = () => {
    console.log("stopping agent...");
    setOpenStopConfirm(true);
  };

  const handleDeleteClicked = () => {
    console.log("deleting agent...");
    setOpenDeleteConfirm(true);
  };

  const handleStartClicked = () => {
    console.log("starting agent...");
    startAgent(name, token).then((data) => {
      console.log("agent started");
    });
  };

  const handleConfirmDelete = () => {
    console.log("deleting agent...");
    deleteAgent(name, token).then((data) => {
      console.log("agent deleted");
      setOpenDeleteConfirm(false);
    });
  };

  const handleConfirmStop = () => {
    console.log("stopping agent...");
    stopAgent(name, token).then((data) => {
      console.log("agent stopped");
      setOpenStopConfirm(false);
    });
  };

  function convertHttpsToWss(httpsUrl) {
    const url = new URL(httpsUrl);
    // Replace the protocol
    url.protocol = url.protocol.replace("https", "wss");
    return url.toString();
  }

  //   const vncURL = convertHttpsToWss(
  //     getAPIAddr() + "/ws/vnc/" + name + "?token=" + token
  //   );
  //   const vncURL = useMemo(
  //     () =>
  //       convertHttpsToWss(getAPIAddr() + "/ws/vnc/" + name + "?token=" + token),
  //     [name, token]
  //   );

  //   console.log("vncURL: ", vncURL);

  const vncURL = useMemo(
    () =>
      convertHttpsToWss(getAPIAddr() + "/ws/vnc/" + name + "?token=" + token),
    [name, token]
  );

  useEffect(() => {
    if (token !== undefined) {
      const handleStart = async () => {
        var agnt0 = await getAgent(name, token);
        if (!agnt0) {
          return;
        }
        setAgent(agnt0);
        console.log("agent0 status: ", agnt0.status);
        if (agnt0.status !== "running" && agnt0.status !== "stopped") {
          console.log("getting job...");
          getJobById(agnt0.create_job_id, token).then((data) => {
            console.log("got job: ");
            console.log(data);
            setJob(data);
          });
        }
      };
      handleStart();
      // Then set the interval
      const intervalId = setInterval(async () => {
        var agnt = await getAgent(name, token);
        if (!agnt) {
          return;
        }
        console.log("setting agnt: ");
        console.log(agnt);
        setAgent(agnt);
        console.log("agent status: ", agnt.status);
        if (agnt.status !== "running" && agnt.status !== "stopped") {
          console.log("getting job...");
          getJobById(agnt.create_job_id, token).then((data) => {
            console.log("got job: ");
            console.log(data);
            setJob(data);
          });
        }
      }, 2000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [token, name]);

  useEffect(() => {
    if (token !== undefined) {
      const handleStart = async () => {
        var tasks = await getAgentTasks(name, token);
        if (!tasks) {
          return;
        }
        setAgentTasks(tasks);
        console.log("got agent tasks: ", tasks);
      };
      handleStart();
      // Then set the interval
      const intervalId = setInterval(async () => {
        var tasks_ = await getAgentTasks(name, token);
        if (!tasks_) {
          return;
        }
        setAgentTasks(tasks_);
        console.log("got agent tasks: ", tasks_);
      }, 2000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [token, name]);

  useEffect(() => {
    console.log("!!!agent:");
    console.log(agent);
  }, [agent]);

  return (
    <>
      <Dialog open={openTaskModal} handler={handleOpenTaskModal}>
        <DialogHeader>
          <PlusCircleIcon className="h-5 w-5 mr-4" />
          Create a Task
        </DialogHeader>
        <DialogBody>
          <div>
            <Textarea
              className=""
              label="Describe the task"
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="mt-4">
            <Input
              className=""
              label="Max steps"
              type="text"
              value={maxSteps}
              onChange={(e) => setMaxSteps(e.target.value)}
            />
          </div>
          <div className="mt-4">
            <Input
              className=""
              label="Starting URL (optional)"
              type="text"
              value={startingURL}
              onChange={(e) => setStartingURL(e.target.value)}
            />
          </div>
          <div className="mt-4">
            <Button
              className="float-right mt-2 mb-4 w-full"
              variant="outlined"
              rounded
              type="submit"
              onClick={handleSubmitTask}
            >
              Start
            </Button>
          </div>
        </DialogBody>
        <DialogFooter></DialogFooter>
      </Dialog>
      <Dialog open={openDeleteConfirm} handler={handleOpenDeleteConfirm}>
        <DialogHeader>
          <ExclamationTriangleIcon className="h-5 w-5 mr-4" />
          Are you sure you want to delete this agent?
        </DialogHeader>
        <DialogBody>
          <div className="mt-4 flex flex-row items-center justify-center gap-6">
            <Button
              className="mt-2 mb-4"
              variant="outlined"
              color="red"
              rounded
              type="submit"
              onClick={() => {
                setOpenDeleteConfirm(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="mt-2 mb-4"
              variant="outlined"
              rounded
              color="blue"
              type="submit"
              onClick={() => {
                handleConfirmDelete();
                setOpenDeleteConfirm(false);
                navigate("/agents");
              }}
            >
              Confirm
            </Button>
          </div>
        </DialogBody>
        <DialogFooter></DialogFooter>
      </Dialog>
      <Dialog open={openStopConfirm} handler={handleOpenStopConfirm}>
        <DialogHeader>
          <ExclamationTriangleIcon className="h-5 w-5 mr-4" />
          Are you sure you want to stop this agent?
        </DialogHeader>
        <DialogBody>
          <div className="mt-4 flex flex-row items-center justify-center gap-6">
            <Button
              className="mt-2 mb-4"
              variant="outlined"
              color="red"
              rounded
              type="submit"
              onClick={() => {
                setOpenStopConfirm(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="mt-2 mb-4"
              variant="outlined"
              rounded
              color="blue"
              type="submit"
              onClick={() => {
                handleConfirmStop();
                setOpenStopConfirm(false);
              }}
            >
              Confirm
            </Button>
          </div>
        </DialogBody>
        <DialogFooter></DialogFooter>
      </Dialog>
      <Layout>
        <div className="flex flex-row mt-10 mx-10 justify-center w-screen max-h-screen">
          {agent &&
            (agent.status === "defined" ||
              agent.status === "creating" ||
              agent.status === "created" ||
              agent.status === "restarting") && (
              <>
                <div className="flex flex-col border border-black-400 p-6 rounded-xl ml-12 min-w-[400px] h-fit">
                  <div className="flex flex-row">
                    <Avatar src={agent.icon} alt="avatar" className="mr-4" />
                    <Typography
                      variant="h4"
                      className="font-tifax items-center flex flex-row font-normal"
                    >
                      {agent.name}
                    </Typography>
                  </div>
                  <div className="mt-8">
                    <div className="w-fit grid grid-cols-4 ">
                      <div className="text-left items-center justify-start p-2">
                        <Typography variant="small" className="font-bold mt-1">
                          Status
                        </Typography>
                      </div>
                      <div className="text-start col-span-3 justify-start p-2 font-bold">
                        <Chip
                          value={agent.status}
                          size="md"
                          variant="ghost"
                          color="blue"
                          className="rounded-full w-fit"
                        />
                      </div>
                      <div className="rounded-rull text-start items-center justify-start p-2">
                        <Typography variant="small" className="font-bold mt-1">
                          Type
                        </Typography>
                      </div>
                      <div className="col-span-3 rounded-r-full text-start justify-start p-2 font-bold">
                        <Chip
                          value={agent.type}
                          size="md"
                          variant="ghost"
                          color="purple"
                          className="rounded-full w-fit"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center mr-36 w-full">
                  <div className="ml-24 mb-8 flex flex-row">
                    <Spinner className="h-8 w-8 mr-4" />
                    <Typography variant="h2">Creating agent...</Typography>
                    <Button
                      color="red"
                      variant="text"
                      onClick={handleDeleteClicked}
                      size="lg"
                      className="normal-case flex items-center gap-3 ml-6"
                    >
                      <XCircleIcon className="w-4 h-4" />
                      Delete
                    </Button>
                  </div>
                  <div className="ml-24">
                    {job &&
                      job.logs &&
                      job.logs
                        .split(/\r?\n/)
                        .map((line, index) => <p key={index}>{line}</p>)}
                  </div>
                  <div className="ml-24 mt-8 flex flex-row">
                    {job && job.status && (
                      <>
                        <Typography variant="lead" className="font-bold mr-2">
                          Status
                        </Typography>
                        <Chip
                          size="sm"
                          variant="ghost"
                          color="blue"
                          value={job.status}
                        ></Chip>
                      </>
                    )}
                  </div>
                  <div className="ml-24 flex flex-row">
                    {/* {job && job.result && (
                      <Typography variant="lead">
                        Result: {job.result}
                      </Typography>
                    )} */}
                  </div>
                </div>
              </>
            )}
          {agent &&
            (agent.status === "running" ||
              agent.status === "stopped" ||
              agent.status === "deleting") && (
              <div className="flex flex-row">
                {/* <div className="flex flex-col min-w-[50px] max-w-[100px] gap-4 mt-96 border border-black-400 h-36 w-fit rounded-2xl p-4 items-center justify-center">
                  <div
                    className={`p-2 rounded-lg ${
                      selectedIcon === "clipboard"
                        ? "bg-blue-gray-50"
                        : "hover:bg-blue-gray-50"
                    }`}
                    onClick={() => setSelectedIcon("clipboard")}
                  >
                    <ClipboardIcon className="h-5 w-5 cursor-pointer" />
                  </div>
                  <div
                    className={`p-2 rounded-lg ${
                      selectedIcon === "code"
                        ? "bg-blue-gray-50"
                        : "hover:bg-blue-gray-50"
                    }`}
                    onClick={() => setSelectedIcon("code")}
                  >
                    <CodeBracketIcon className="h-5 w-5 cursor-pointer" />
                  </div>
                </div> */}
                <div className="flex flex-col min-w-[450px] max-w-[500px]">
                  <div className="flex flex-col border border-black-400 p-6 rounded-xl">
                    <div className="flex flex-row">
                      <Avatar src={agent.icon} alt="avatar" className="mr-4" />
                      <Typography
                        variant="h4"
                        className="font-tifax items-center flex flex-row font-normal"
                      >
                        {agent.name}
                      </Typography>
                      {showCreateTaskButtonSmall ? (
                        <div className="flex flex-row w-full justify-end items-center">
                          <Button
                            variant="outlined"
                            onClick={handleCreateTask}
                            className="normal-case w-36 h-10 flex items-center gap-3 border-black-900"
                          >
                            <PlusCircleIcon className="w-4 h-4" />
                            New Task
                          </Button>
                        </div>
                      ) : (
                        <div className="flex flex-row w-full justify-end items-center">
                          <div className="flex flex-row">
                            <Spinner className="h-4 w-4 mr-3" />
                            <Typography variant="small" className="font-bold">
                              Task in progress
                            </Typography>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mt-8">
                      <div className="w-fit grid grid-cols-4 ">
                        <div className="text-left items-center justify-start p-2">
                          <Typography
                            variant="small"
                            className="font-bold mt-1"
                          >
                            Status
                          </Typography>
                        </div>
                        <div className="text-start col-span-3 justify-start p-2 font-bold">
                          <Chip
                            value={agent.status}
                            size="md"
                            variant="ghost"
                            color="blue"
                            className="rounded-full w-fit"
                          />
                        </div>
                        <div className="rounded-rull text-start items-center justify-start p-2">
                          <Typography
                            variant="small"
                            className="font-bold mt-1"
                          >
                            Type
                          </Typography>
                        </div>
                        <div className="col-span-3 rounded-r-full text-start justify-start p-2 font-bold">
                          <Chip
                            value={agent.type}
                            size="md"
                            variant="ghost"
                            color="purple"
                            className="rounded-full w-fit"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row gap-4 mt-1 border border-black-400 w-fit h-fit rounded-md items-center justify-center w-full">
                    <div
                      className={`p-2 rounded-md ${
                        selectedIcon === "clipboard"
                          ? "bg-blue-gray-50"
                          : "hover:bg-blue-gray-50"
                      }`}
                      onClick={() => setSelectedIcon("clipboard")}
                    >
                      <ClipboardIcon className="h-5 w-5 cursor-pointer" />
                    </div>
                    <div
                      className={`p-2 rounded-md ${
                        selectedIcon === "code"
                          ? "bg-blue-gray-50"
                          : "hover:bg-blue-gray-50"
                      }`}
                      onClick={() => setSelectedIcon("code")}
                    >
                      <CodeBracketIcon className="h-5 w-5 cursor-pointer" />
                    </div>
                  </div>
                  {showCreateTaskButtonBig && selectedIcon === "clipboard" && (
                    <motion.div
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.5 }}
                      className="border border-black-400 rounded-xl mt-10 p-10"
                    >
                      <Typography
                        variant="h5"
                        className="font-tifax text-center justify-center"
                      >
                        Create a task to start surfing 🏄‍♂️
                      </Typography>
                      <motion.div
                        whileHover={{ scale: 1.05 }}
                        transition={{ duration: 0.5 }}
                        className="flex flex-row items-center justify-center text-center w-full mt-10"
                      >
                        <Button
                          size="lg"
                          className="flex flex-row w-full items-center justify-center gap-3"
                          onClick={handleCreateTask}
                        >
                          <PlusCircleIcon className="w-5 h-5" />
                          Create Task
                        </Button>
                      </motion.div>
                    </motion.div>
                  )}
                  {showLoadingAgentVid && (
                    <div className="pt-24 min-w-[400px] md:ml-10">
                      <video className="h-72 rounded-full" autoPlay loop muted>
                        <source
                          src="https://storage.googleapis.com/guisurfer-assets/Gen-2%208s%2C%20969073933%2C%20M%205.mp4"
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}
                  {agentTasks &&
                    agentTasks.length > 0 &&
                    selectedIcon === "clipboard" && (
                      <div className="max-h-screen overflow-y-scroll min-w-[400px] mt-1">
                        <Task data={agentTasks[0]} key={0} />
                      </div>
                    )}
                  {selectedIcon === "code" && (
                    <div className="border border-black-400 rounded-md mt-1">
                      <Typography variant="h6" className="ml-4 mb-2">
                        Logs ▽
                      </Typography>
                      <div
                        // initial={{ opacity: 0, scale: 0.5 }}
                        // animate={{ opacity: 1, scale: 1 }}
                        // transition={{ duration: 0.5 }}
                        ref={logsContainerRef}
                        className="mt-1 w-[500px] h-96 border-t border-t-black-400 overflow-y-scroll rounded-md p-2 min-w-full"
                      >
                        {logs &&
                          logs.map((log, index) => (
                            <div
                              key={index}
                              className="border-b border-gray-200 p-2"
                            >
                              {log}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          {agent && agent.status === "stopped" && (
            <div className="ml-24 flex flex-col w-6/12">
              {" "}
              <div className="ml-24 mb-8 mt-24 flex flex-row gap-10">
                <Typography variant="h2">Agent is stopped</Typography>
                <Button
                  color="green"
                  variant="outlined"
                  onClick={handleStartClicked}
                  className="flex flex-row gap-3 items-center"
                >
                  <PlayCircleIcon className="w-4 h-4" />
                  Start
                </Button>
              </div>
            </div>
          )}
          {agent && agent.status === "running" && (
            <div className="ml-8 flex flex-col">
              <div className="border border-black flex w-fit h-fit shadow-2xl">
                <VncScreen
                  url={vncURL}
                  scaleViewport
                  background="#000000"
                  style={{ width: "900px", height: "720px" }}
                  rfbOptions={{
                    credentials: { password: "agentsea123" },
                    shared: true,
                  }}
                  ref={ref}
                  debug={true}
                  showDotCursor={true}
                  onConnect={(rfb) => {
                    console.log(
                      `CHANGE Connected to VNC server at timestamp:`,
                      Date.now()
                    );
                    console.log(rfb);
                  }}
                  onDisconnect={(rfb) => {
                    console.log(
                      `CHANGE Disconnected from VNC server at timestamp:`,
                      Date.now()
                    );
                    console.log(rfb);
                  }}
                  onSecurityFailure={(e) =>
                    console.log(
                      `CHANGE Security failure: ${e.detail} at timestamp:`,
                      Date.now()
                    )
                  }
                  //   loadingUI={<div>Connecting to VNC server...</div>}
                />
              </div>
              <div className="flex flex-row float-right justify-center border border-black-400 gap-4 p-4">
                <Button
                  color="blue"
                  variant="text"
                  size="lg"
                  onClick={handleStopClicked}
                  className="normal-case flex items-center gap-3"
                >
                  <StopCircleIcon className="w-4 h-4" />
                  Stop
                </Button>
                <Button
                  color="red"
                  variant="text"
                  onClick={handleDeleteClicked}
                  size="lg"
                  className="normal-case flex items-center gap-3"
                >
                  <XCircleIcon className="w-4 h-4" />
                  Delete
                </Button>
              </div>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
}
