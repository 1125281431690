import React from "react";
import { Typography } from "@material-tailwind/react";
import { CopyBlock, nord } from "react-code-blocks";
import DocsLayout from "../../components/DocsLayout";

export default function GettingStartedPage() {
  return (
    <DocsLayout>
      <div className="flex flex-col gap-6">
        <Typography variant="h2">Getting Started</Typography>
        <Typography variant="h3">Installation</Typography>
        <div className="">
          <CopyBlock
            text="pip install agentsea"
            language="text"
            showLineNumbers={false}
            theme={nord}
            wrapLines
          />
        </div>
        <div>Login to the hub</div>
        <div className="">
          <CopyBlock
            text="agentsea login"
            language="text"
            showLineNumbers={false}
            theme={nord}
            wrapLines
          />
        </div>
        <Typography variant="h3">Concepts</Typography>
      </div>
    </DocsLayout>
  );
}
