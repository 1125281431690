import React from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
  Typography,
} from "@material-tailwind/react";

function DesktopRuntimeModal({ runtime, onClose, onDelete }) {
  if (!runtime) return null; // Do not render if no runtime is provided

  return (
    <Dialog open={Boolean(runtime)} handler={onClose}>
      <DialogHeader>Runtime Details</DialogHeader>
      <DialogBody>
        <Typography>Name: {runtime.name}</Typography>
        <Typography>Provider: {runtime.provider}</Typography>
        {/* Include other details as needed */}
      </DialogBody>
      <DialogFooter>
        <Button
          color="red"
          onClick={() => onDelete(runtime.name)}
          className="mr-4"
          variant="outlined"
        >
          Delete
        </Button>
        <Button color="blue" onClick={onClose} variant="outlined">
          Close
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default DesktopRuntimeModal;
