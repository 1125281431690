import { Typography } from "@material-tailwind/react";
import Layout from "../components/Layout";
import { motion } from "framer-motion";

export default function HelpPage() {
  return (
    <Layout>
      <div className="flex flex-row mt-10">
        <div className="items-center justify-center flex flex-col w-full mt-24">
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="border border-black-400 rounded-xl mt-10 p-20 shadow-xl bg-white"
          >
            <Typography
              variant="h5"
              className="font-tifax text-center justify-center"
            >
              Need help? 🏄‍♂️
            </Typography>
            <Typography
              variant="paragraph"
              className="text-center justify-center mt-8"
            >
              Contact{" "}
              <a
                href="mailto:patrick.barker@kentauros.ai"
                className="text-blue-500"
              >
                patrick.barker@kentauros.ai
              </a>
            </Typography>
          </motion.div>
        </div>
      </div>
    </Layout>
  );
}
