import { Typography } from "@material-tailwind/react";
import Layout from "../components/Layout";
import { BlockCard } from "../components/BlockCard";

export default function BlocksPage() {

    return (
        <Layout>
            <div className="pl-16 mt-20">
                <Typography className="text-3xl font-bold">Building Blocks</Typography>
            </div>
            <div className="mx-auto flex flex-wrap p-16">
                <BlockCard
                    name="Avatar Creator"
                    description="Create lifelike avatars for your agent"
                    route="/avatars"
                    bgcolor="#0ea5e9"
                    imgURL="https://storage.googleapis.com/agentsea-assets/amelia_card.png"
                />
                <BlockCard
                    name="Voice Generator"
                    description="Generate lifelike voices for your agent"
                    route="/voices"
                    bgcolor="#404040"
                    imgURL="https://cdn.vectorstock.com/i/preview-1x/64/64/colorful-gradient-sound-waveform-graphic-vector-31336464.jpg"
                />
            </div>
        </Layout>
    )
}