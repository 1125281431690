// Inside AuthProvider component
import React, { createContext, useState, useEffect } from "react";
import { auth } from "../config/Firebase"; // Make sure this path is correct

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true); // Initialize loading state to true

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      if (user) {
        const token = await user.getIdToken(true);
        setToken(token);
      } else {
        setToken(null);
      }
      setLoading(false); // Set loading to false once user state is determined
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, token, loading }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
