import {
    Typography,
    Avatar,
} from "@material-tailwind/react";
import { motion } from "framer-motion";

export function AgentCard({ name, description, imgURL, onClick }) {
    return (
        <motion.div
            initial={{ scale: 1 }}
            whileHover={{ scale: 1.02 }}
        >
            <div className="mt-6 w-36 h-36 ml-4 cursor-pointer shadow-xl rounded-full border border-gray-200" onClick={onClick}>

                <div className="flex items-center justify-center p-0 m-0 w-full h-full">
                    <Avatar src={imgURL} alt="avatar" size="xxl" />
                </div>
                <div className="text-center pt-2">
                    <Typography variant="h6" color="blue-gray bg-gray-200" className="mb-2">
                        {name}
                    </Typography>
                </div>
            </div>
        </motion.div >
    );
}