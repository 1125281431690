import React, { useState } from "react";
import Layout from "./Layout";
import { DocsSidebar } from "./DocsSidebar";

export default function DocsLayout({ children }) {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <Layout>
      <div className="flex flex-row mt-8 justify-center">
        <div className="">
          <DocsSidebar open={open} handleOpen={handleOpen} />
        </div>
        <div className="ml-1 pl-16 pr-16 w-11/12 pt-8 flex flex-col max-w-3xl bg-white w-full rounded-xl">
          {children}
        </div>
      </div>
    </Layout>
  );
}
