import { Typography, Spinner } from "@material-tailwind/react";
import Layout from "../components/Layout";
import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../server/AuthContext";
import { CopyPasswordText } from "../components/CopyText";
import { userInfo, userKeys } from "../api/User";
import { KeyIcon } from "@heroicons/react/24/outline";

export default function CLILoginPage() {
  const { currentUser, token } = useContext(AuthContext);
  console.log("current user");
  console.log(currentUser);

  const [userInfoData, setUserInfoData] = useState(null);
  const [userKeysData, setUserKeysData] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const data = await userInfo(token);
        console.log("got user info:");
        console.log(data);
        setUserInfoData(data);
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();

    const fetchUserKeys = async () => {
      try {
        const data = await userKeys(token);
        console.log("got user keys:");
        console.log(data);
        setUserKeysData(data);
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserKeys();
  }, [token]);

  return (
    <Layout>
      <div className="flex justify-center mt-36">
        <div className="p-16 mt-16 flex flex-col items-center border border-solid b-2 shadow-2xl rounded-2xl">
          <div className="flex flex-row">
            <KeyIcon className="h-8 w-8 mr-4" />
            <Typography className="text-3xl mb-6 font-bold">
              Copy the API key back to the CLI
            </Typography>
          </div>
          <div className="w-fit">
            {userKeysData && userKeysData.length > 0 ? (
              <CopyPasswordText text={userKeysData[0].key} />
            ) : (
              <Spinner className="h-10 w-10" />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
