// App.js
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./server/AuthContext";
import AppRoutes from "./server/Routes";

function App() {
  return (
    <div className="bg-gray-100 min-h-screen w-full overflow-x-hidden">
      <AuthProvider>
        <Router>
          <AppRoutes />
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
