import React from "react";
import { Typography } from "@material-tailwind/react";
import DocsLayout from "../../components/DocsLayout";
import InlineLink from "../../components/InlineLink";

export default function AgentOverviewPage() {
  return (
    <DocsLayout>
      <div className="flex flex-col gap-6">
        <Typography variant="h2">Agents</Typography>
        <div>
          Agents are LLM or MLM models augmented with tools which enable them to{" "}
          react to the world
        </div>
        <div className="flex items-center justify-center">
          <img
            src="https://storage.googleapis.com/agentsea-assets/agent_diagram.svg"
            alt="agent diagram"
            className="w-7/12 h-7/12"
          />
        </div>
        <div className="flex flex-wrap flex-row">
          Tools are an agent's interface to the world and can be anything from
          email, to telegram, to a robot. See
          <InlineLink to="/docs/tools/overview" text="Tools Overview" /> for
          more details
        </div>
      </div>
    </DocsLayout>
  );
}
