import React, { useState, useContext } from "react";
import {
  Typography,
  Input,
  Button,
  Spinner,
  Select,
  Option,
  Textarea,
} from "@material-tailwind/react";
import { registerDesktopRuntime } from "../api/Runtime";
import { XCircleIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import { AuthContext } from "../server/AuthContext";

export default function RegisterDesktopRuntimeModal({ onClose }) {
  const { token } = useContext(AuthContext);
  console.log("got token from auth context: ", token);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [name, setName] = useState("");
  const [runtime, setRuntime] = useState("");
  const [gceCredentials, setGceCredentials] = useState("");
  const [awsAccessKey, setAwsAccessKey] = useState("");
  const [awsSecretKey, setAwsSecretKey] = useState("");
  const [sharedWith, setSharedWith] = useState([]);
  const [currentString, setCurrentString] = useState("");

  const handleGceCredentialsChange = (e) => {
    setGceCredentials(e.target.value);
  };

  const handleAwsAccessKeyChange = (e) => {
    setAwsAccessKey(e.target.value);
  };

  const handleAwsSecretKeyChange = (e) => {
    setAwsSecretKey(e.target.value);
  };

  const handleStringChange = (e) => setCurrentString(e.target.value);

  const handleStringKeyDown = (e) => {
    if (e.key === "Enter" && currentString.trim() !== "") {
      e.preventDefault(); // Prevent form submission
      if (!sharedWith.includes(currentString.trim())) {
        setSharedWith([...sharedWith, currentString.trim()]);
        setCurrentString(""); // Reset the input field
      }
    }
  };

  const removeString = (indexToRemove) => {
    setSharedWith(sharedWith.filter((_, index) => index !== indexToRemove));
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (runtime === "gce") {
      var credentials = {
        GOOGLE_APPLICATION_CREDENTIALS_JSON: gceCredentials,
      };
    } else if (runtime === "ec2") {
      credentials = {
        AWS_ACCESS_KEY_ID: awsAccessKey,
        AWS_SECRET_ACCESS_KEY: awsSecretKey,
      };
    }

    registerDesktopRuntime(name, runtime, credentials, sharedWith, token)
      .then((response) => {
        // Handle the response here
        console.log("Runtime registered:", response);
        setLoading(false);
      })
      .then(() => {
        setDone(true);
        setLoading(false);
      })
      .then(() => sleep(1000))
      .then(() => onClose())
      .catch((error) => {
        // Handle any errors here
        console.error("Error registering agent:", error);
        setError(true);
        setLoading(false);
      });
  };

  return (
    <div className="">
      {loading && <Spinner className="h-12 w-12" />}
      {error && (
        <div className="flex flex-row">
          <XCircleIcon className="mr-2 w-12 h-12" />
          <Typography className="text-2xl font-bold mb-4">
            Error occurred while registering
          </Typography>
        </div>
      )}
      {done && (
        <div className="flex flex-row">
          <CheckCircleIcon className="mr-2 w-12 h-12" />
          <Typography className="text-2xl font-bold mb-4">
            Registration successful!
          </Typography>
        </div>
      )}

      {!loading && !done && !error && (
        <div className="">
          <form onSubmit={handleSubmit} className="gap-4">
            <div className="mb-4">
              <Input
                className=""
                label="Name of the provider"
                type="text"
                value={name}
                onChange={handleNameChange}
              />
            </div>
            <div className="mb-4">
              <Select
                value={runtime}
                onChange={(e) => setRuntime(e)}
                label="Select runtime"
                selected={(element) =>
                  element &&
                  React.cloneElement(element, {
                    disabled: true,
                    className:
                      "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
                  })
                }
              >
                <Option value="gce" className="flex items-center gap-2">
                  {" "}
                  <img
                    src="https://www.gend.co/hs-fs/hubfs/gcp-logo-cloud.png?width=730&name=gcp-logo-cloud.png"
                    alt="gce"
                    className="h-5 w-5 rounded-full object-cover"
                  />
                  GCE
                </Option>
                <Option value="ec2" className="flex items-center gap-2">
                  {" "}
                  <img
                    src="https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/102017/logo_0.png?17TK91b1B6OvV2MFrCLfukw1c8oEaNr6&itok=vsanFiUj"
                    alt="ec2"
                    className="h-5 w-5 rounded-full object-cover"
                  />
                  EC2
                </Option>
              </Select>
            </div>
            {runtime === "gce" && (
              <Textarea
                type="text"
                label="GCE JSON Key"
                value={gceCredentials}
                onChange={handleGceCredentialsChange}
              />
            )}

            {runtime === "ec2" && (
              <>
                <div className="mb-4">
                  <Input
                    type="text"
                    label="AWS Access Key"
                    className="mb-4"
                    value={awsAccessKey}
                    onChange={handleAwsAccessKeyChange}
                  />
                </div>
                <div className="mb-4">
                  <Input
                    type="password" // Use password type to hide input
                    label="AWS Secret Key"
                    className="mb-4"
                    value={awsSecretKey}
                    onChange={handleAwsSecretKeyChange}
                  />
                </div>
              </>
            )}
            <div className="mb-4">
              <Input
                type="text"
                label="Emails to share with (press Enter to add)"
                value={currentString}
                onChange={handleStringChange}
                onKeyDown={handleStringKeyDown}
              />
              <div className="flex flex-wrap mt-2">
                {sharedWith.map((str, index) => (
                  <div
                    key={index}
                    className="flex items-center mr-2 mb-2 bg-gray-200 p-2 rounded"
                  >
                    {str}
                    <XCircleIcon
                      className="ml-2 h-5 w-5 cursor-pointer"
                      onClick={() => removeString(index)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <Button
              className="float-right mt-2 mb-4 w-full"
              variant="outlined"
              rounded
              onClick={handleSubmit}
            >
              Create
            </Button>
          </form>
        </div>
      )}
    </div>
  );
}
