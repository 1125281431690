import React, { useContext } from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
  Card,
  IconButton,
} from "@material-tailwind/react";
import {
  UserCircleIcon,
  CodeBracketSquareIcon,
  Square3Stack3DIcon,
  ChevronDownIcon,
  HomeIcon,
  ClipboardIcon,
  LifebuoyIcon,
  PowerIcon,
  RocketLaunchIcon,
  Bars2Icon,
  BellIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ComputerDesktopIcon,
  CloudIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/24/outline";
import { AuthContext } from "../server/AuthContext";
import { Link, useLocation, useNavigate } from "react-router-dom";

// profile menu component
const profileMenuItems = [
  {
    label: "Profile",
    icon: UserCircleIcon,
    to: "/profile",
  },
  // {
  //     label: "Edit Profile",
  //     icon: Cog6ToothIcon,
  // },
  // {
  //     label: "Inbox",
  //     icon: InboxArrowDownIcon,
  // },
  {
    label: "Help",
    icon: LifebuoyIcon,
    to: "/help",
  },
  {
    label: "Sign Out",
    icon: PowerIcon,
    to: "/signout",
  },
];

function ProfileMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  console.log("photo URL", currentUser?.photoURL);

  const closeMenu = () => setIsMenuOpen(false);

  async function handleMenuClick(to) {
    navigate(to);
    closeMenu();
  }

  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
      <MenuHandler>
        <Button
          variant="text"
          color="blue-gray"
          className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto"
        >
          <img
            className="border border-gray-900 p-0.5 rounded-full w-9 h-9"
            // src={`${currentUser?.photoURL}?t=${new Date().getTime()}`}
            src={`${currentUser?.photoURL}`}
            alt="profile"
            key={new Date().getTime()}
          />
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`h-3 w-3 transition-transform ${
              isMenuOpen ? "rotate-180" : ""
            }`}
          />
        </Button>
      </MenuHandler>
      <MenuList className="p-1">
        {profileMenuItems.map(({ label, icon, to }, key) => {
          const isLastItem = key === profileMenuItems.length - 1;
          return (
            <MenuItem
              key={label}
              onClick={(e) => handleMenuClick(to)}
              className={`flex items-center gap-2 rounded ${
                isLastItem
                  ? "hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10"
                  : ""
              }`}
            >
              {React.createElement(icon, {
                className: `h-4 w-4 ${isLastItem ? "text-red-500" : ""}`,
                strokeWidth: 2,
              })}
              <Typography
                as="span"
                variant="small"
                className="font-normal"
                color={isLastItem ? "red" : "inherit"}
              >
                {label}
              </Typography>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}

// nav list menu
const navListMenuItems = [
  {
    title: "@material-tailwind/html",
    description:
      "Learn how to use @material-tailwind/html, packed with rich components and widgets.",
  },
  {
    title: "@material-tailwind/react",
    description:
      "Learn how to use @material-tailwind/react, packed with rich components for React.",
  },
  {
    title: "Material Tailwind PRO",
    description:
      "A complete set of UI Elements for building faster websites in less time.",
  },
];

function NavListMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const renderItems = navListMenuItems.map(({ title, description }) => (
    <a href="#" key={title}>
      <MenuItem>
        <Typography variant="h6" color="blue-gray" className="mb-1">
          {title}
        </Typography>
        <Typography variant="small" color="gray" className="font-normal">
          {description}
        </Typography>
      </MenuItem>
    </a>
  ));

  return (
    <React.Fragment>
      <Menu allowHover open={isMenuOpen} handler={setIsMenuOpen}>
        <MenuHandler>
          <Typography as="a" href="#" variant="small" className="font-normal">
            <MenuItem className="hidden items-center gap-2 text-blue-gray-900 lg:flex lg:rounded-full">
              <Square3Stack3DIcon className="h-[18px] w-[18px]" /> Feed{" "}
            </MenuItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden w-[36rem] grid-cols-7 gap-3 overflow-visible lg:grid">
          <Card
            color="blue"
            shadow={false}
            variant="gradient"
            className="col-span-3 grid h-full w-full place-items-center rounded-md"
          >
            <RocketLaunchIcon strokeWidth={1} className="h-28 w-28" />
          </Card>
          <ul className="col-span-4 flex w-full flex-col gap-1">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <MenuItem className="flex items-center gap-2 text-blue-gray-900 lg:hidden">
        <Square3Stack3DIcon className="h-[18px] w-[18px]" /> Pages{" "}
      </MenuItem>
      <ul className="ml-6 flex w-full flex-col gap-1 lg:hidden">
        {renderItems}
      </ul>
    </React.Fragment>
  );
}

// nav list component
const navListItems = [
  {
    label: "Agents",
    icon: UserCircleIcon,
    route: "/agents",
  },
  // {
  //   label: "Devices",
  //   icon: ComputerDesktopIcon,
  //   route: "/devices",
  // },
  {
    label: "Tasks",
    icon: ClipboardIcon,
    route: "/tasks",
  },
  {
    label: "Boards",
    icon: PresentationChartLineIcon,
    route: "/boards",
  },
  {
    label: "Docs",
    icon: CodeBracketSquareIcon,
    route: "https://docs.hub.agentsea.ai/introduction",
  },
];

function NavList() {
  const location = useLocation();

  return (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center">
      {navListItems.map(({ label, icon, route }, key) =>
        route.startsWith("http") ? (
          <a href={route} key={key} target="_blank" rel="noopener noreferrer">
            <Typography
              key={label}
              as="span"
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
              <MenuItem
                className={`flex items-center gap-2 lg:rounded-full ${
                  location.pathname.startsWith(route)
                    ? "bg-blue-gray-50 text-blue-gray-900"
                    : ""
                } hover:bg-blue-gray-50 hover:text-blue-gray-900`}
              >
                {React.createElement(icon, { className: "h-[18px] w-[18px]" })}{" "}
                {label}
              </MenuItem>
            </Typography>
          </a>
        ) : (
          <Link to={route} key={key}>
            <Typography
              key={label}
              as="span"
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
              <MenuItem
                className={`flex items-center gap-2 lg:rounded-full ${
                  location.pathname.startsWith(route)
                    ? "bg-blue-gray-50 text-blue-gray-900"
                    : ""
                } hover:bg-blue-gray-50 hover:text-blue-gray-900`}
              >
                {React.createElement(icon, { className: "h-[18px] w-[18px]" })}{" "}
                {label}
              </MenuItem>
            </Typography>
          </Link>
        )
      )}
    </ul>
  );
}

export function Nav() {
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const navigate = useNavigate();

  const toggleIsNavOpen = () => setIsNavOpen((cur) => !cur);
  const { currentUser } = useContext(AuthContext);

  console.log("Current user!");
  console.log(currentUser);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsNavOpen(false)
    );
  }, []);

  return (
    <Navbar className="mx-auto max-w-screen-xl p-2 lg:rounded-full mt-4 lg:pl-6">
      <div className="relative mx-auto flex items-center justify-between text-blue-gray-900">
        <img
          className="h-9 w-9 rounded-full object-contain object-center rounded-full"
          src="https://storage.googleapis.com/guisurfer-assets/logo_black.png"
          alt="logo"
        />
        <div className="absolute top-2/4 left-2/4 hidden -translate-x-2/4 -translate-y-2/4 lg:block">
          <NavList />
        </div>
        <IconButton
          size="sm"
          color="blue-gray"
          variant="text"
          onClick={toggleIsNavOpen}
          className="ml-auto mr-2 lg:hidden"
        >
          <Bars2Icon className="ml-auto h-6 w-6" />
        </IconButton>
        {/* <BellIcon className="h-6 w-6 ml-auto" color="blue-gray" /> */}
        {/* <ChatBubbleOvalLeftEllipsisIcon className="h-6 w-6 ml-auto" color="blue-gray" /> */}
        <div className="flex items-center gap-2">
          {/* Uncomment below */}
          {/* <ChatBubbleOvalLeftEllipsisIcon
            className="h-[22px] w-[22px] cursor-pointer"
            color="blue-gray"
          />
          <BellIcon
            className="h-[22px] w-[22px] cursor-pointer"
            color="blue-gray"
          /> */}
          {currentUser ? (
            <ProfileMenu />
          ) : (
            // <Button
            //   variant="text"
            //   color="blue-gray"
            //   className="rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto"
            //   onClick={() => navigate("/login")}
            // >
            //   Login
            // </Button>
            <Button
              size="sm"
              onClick={() => navigate("/login")}
              className="mr-4"
            >
              Login
            </Button>
          )}
        </div>
        {/* <ProfileMenu /> */}
      </div>
      <MobileNav open={isNavOpen} className="overflow-scroll">
        <NavList />
      </MobileNav>
    </Navbar>
  );
}
