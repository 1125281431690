import React, { useState } from "react";
import { ClipboardDocumentIcon, CheckIcon } from "@heroicons/react/24/outline";

export function CopyText({ text }) {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000); // Hide the message after 2 seconds
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  };

  return (
    <div className="border-solid border-2 border-gray-300 rounded-md p-2 flex flex-row items-center">
      <p>{text}</p>
      <div>
        <ClipboardDocumentIcon
          className="h-4 w-4 ml-4 cursor-pointer"
          onClick={copyToClipboard}
        />
      </div>
      {isCopied && <div className="ml-2 text-sm text-green-500">Copied!</div>}
    </div>
  );
}

export function CopyPasswordText({ text }) {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000); // Hide the message after 2 seconds
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  };

  const maskText = (text) => {
    // Change the number 3 to show more or fewer characters
    const visibleCharacters = 3;
    if (text.length <= visibleCharacters) {
      return text; // No masking for short strings
    }
    return (
      "*".repeat(text.length - visibleCharacters) +
      text.slice(-visibleCharacters)
    );
  };

  return (
    <div className="border-solid border-2 border-gray-300 rounded-md p-2 flex flex-row">
      <p>{maskText(text)}</p>

      {isCopied ? (
        <div className="float-right">
          <CheckIcon className="h-5 w-5 ml-4 cursor-pointer text-green-500" />
        </div>
      ) : (
        <div className="float-right">
          <ClipboardDocumentIcon
            className="h-5 w-5 ml-4 cursor-pointer"
            onClick={copyToClipboard}
          />
        </div>
      )}
    </div>
  );
}
