import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { app, auth } from "../config/Firebase";
import LandingLayout from "../components/LandingLayout";
import { useNavigate, useLocation } from "react-router-dom";
import { userInfo } from "../api/User";
import { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
} from "@material-tailwind/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { checkUserHandle, setUserInfo } from "../api/User";

export default function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const googleProvider = new GoogleAuthProvider();

  const [userName, setUserName] = useState("");
  const [userExists, setUserExists] = useState(null);
  const [token, setToken] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [openRegister, setOpenRegister] = useState(false);
  const handleOpenRegister = () => setOpenRegister(!openRegister);

  const from = location.state?.from?.pathname || "/agents";

  const handleGoogleSignIn = async () => {
    try {
      console.log("handling google signin");
      console.log("App:", app);
      console.log("Auth:", auth);
      console.log("Google Provider:", googleProvider);
      const result = await signInWithPopup(auth, googleProvider);
      console.log("result: ", result);
      const tkn = await result.user.getIdToken();
      setToken(tkn);
      const info = await userInfo(tkn);
      console.log("got info: ", info);

      if (info.handle === undefined || info.handle === null) {
        console.log("handle undefined!");
        const email = result.user.email;
        const usernamePart = email.split("@")[0];
        const randomNumber = Math.floor(Math.random() * 10000); // Generates a random number between 0 and 9999
        var defaultUsername = `${usernamePart}${randomNumber}`;
        defaultUsername = defaultUsername.replace(/[_\.]/g, "-");
        setUserName(defaultUsername); // Set the default username
        setOpenRegister(true);
      } else {
        console.log("handle defined!");
        navigate(from);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Add a function to validate the username according to the new rules
  const validateUsername = (username) => {
    if (!/^[a-zA-Z0-9-]+$/.test(username)) {
      return "Username can only contain letters, numbers, and dashes.";
    }
    // Add any other validations here if necessary
    return "";
  };

  const handleRegister = async (handle) => {
    setUserInfo(token, handle);
    navigate(from);
  };

  useEffect(() => {
    const checkUsername = async (name) => {
      const validationError = validateUsername(name);
      if (validationError !== "") {
        setValidationMessage(validationError); // Set the error message
        setUserExists(true); // Indicate that there's a validation error
      } else {
        const exists = await checkUserHandle(token, name);
        setUserExists(exists);
        // Clear any previous validation messages if the username passes validation
        setValidationMessage("");
      }
    };
    if (userName.length > 0) {
      checkUsername(userName);
    }
  }, [userName, token]);

  return (
    <LandingLayout>
      {" "}
      <Dialog open={openRegister} handler={handleOpenRegister}>
        <DialogHeader>
          <PencilSquareIcon className="h-5 w-5 mr-4" />
          Register User
        </DialogHeader>
        <DialogBody>
          <Typography className="text-xl font-bold mb-4">
            Pick a username
          </Typography>
          <Input
            type="text"
            color="lightBlue"
            size="regular"
            outline={true}
            label="Username"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            error={userExists}
            success={!userExists && userName.length > 0}
          />
          {/* Optionally display the validation message if there is one */}
          <div className="text-sm mt-2 h-6">
            {validationMessage ? (
              <Typography className="text-sm mt-2">
                {validationMessage}
              </Typography>
            ) : null}
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            color="lightBlue"
            onClick={() => {
              handleRegister(userName);
            }}
            ripple="light"
            variant="outlined"
            disabled={userExists || !userName}
          >
            Register
          </Button>
        </DialogFooter>
      </Dialog>
      <div className="mx-auto w-[450px] max-w-screen-sm rounded-xl border border-grey-700 flex flex-col text-center items-center shadow-2xl justify-center p-16 mt-36">
        <img
          className="h-48 w-48 rounded-full object-contain object-center rounded-full"
          src="https://storage.googleapis.com/guisurfer-assets/logo_black.png"
          alt="logo"
        />

        <div className="mt-8 justify-center">
          <Button
            size="lg"
            variant="outlined"
            color="blue-gray"
            className="flex items-center gap-3 mx-auto"
            onClick={handleGoogleSignIn}
          >
            <img
              src="https://www.svgrepo.com/show/303108/google-icon-logo.svg"
              alt="metamask"
              className="h-6 w-6"
            />
            Continue with Google
          </Button>
        </div>
      </div>
    </LandingLayout>
  );
}
