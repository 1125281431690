import {
  Typography,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Card,
  CardHeader,
  CardBody,
} from "@material-tailwind/react";
import { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { useContext } from "react";
import { AuthContext } from "../server/AuthContext";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import PlusButtonText from "../components/PlusButtonText";
import RegisterDesktopRuntimeModal from "../components/RegisterDesktopRuntimeModal";
import RegisterAgentRuntimeModal from "../components/RegisterAgentRuntimeModal";
import { listDesktopRuntimes, listAgentRuntimes } from "../api/Runtime";
import DesktopRuntimeModal from "../components/DesktopRuntimeModal";
import AgentRuntimeModal from "../components/AgentRuntimeModal";
import { deleteAgentRuntime } from "../api/Runtime";
import { deleteDesktopRuntime } from "../api/Runtime";
import { motion } from "framer-motion";

export default function RuntimesPage() {
  const { token } = useContext(AuthContext);
  console.log("got token from auth context: ", token);
  const [providers, setProviders] = useState([]);
  const [agentRuntimes, setAgentRuntimes] = useState([]);

  const [openRegister, setOpenRegister] = useState(false);
  const handleOpenRegister = () => setOpenRegister(!openRegister);
  const handleCloseRegister = () => setOpenRegister(false);

  const [openAgentRegister, setOpenAgentRegister] = useState(false);
  const handleOpenAgentRegister = () =>
    setOpenAgentRegister(!openAgentRegister);
  const handleCloseAgentRegister = () => setOpenAgentRegister(false);

  const getImageUrlForProviderType = (providerType) => {
    const imageMapping = {
      ec2: "https://www.logicata.com/wp-content/uploads/2020/01/EC2.png",
      gce: "https://download.logo.wine/logo/Google_Compute_Engine/Google_Compute_Engine-Logo.wine.png",
      gke: "https://www.ibm.com/content/dam/adobe-cms/instana/media_logo/GKE.png/_jcr_content/renditions/cq5dam.web.1280.1280.png",
    };

    return (
      imageMapping[providerType] ||
      "https://iheartcraftythings.com/wp-content/uploads/2021/06/Cloud_5-1.jpg"
    );
  };

  const [selectedDesktopRuntime, setSelectedDesktopRuntime] = useState(null);
  // States for agent runtimes
  const [selectedAgentRuntime, setSelectedAgentRuntime] = useState(null);

  // Handle desktop runtime clicks
  const handleDesktopRuntimeClick = (runtime) => {
    setSelectedDesktopRuntime(runtime);
  };

  // Handle agent runtime clicks
  const handleAgentRuntimeClick = (runtime) => {
    setSelectedAgentRuntime(runtime);
  };

  // Separate delete functions
  const deleteDesktopRuntimeFunc = async (runtimeId) => {
    console.log(`Deleting desktop runtime with ID: ${runtimeId}`);
    // Implement deletion logic here
    // Close the modal and update state as needed
    setSelectedDesktopRuntime(null);
    deleteDesktopRuntime(runtimeId, token);
  };

  const deleteAgentRuntimeFunc = async (runtimeId) => {
    console.log(`Deleting agent runtime with ID: ${runtimeId}`);
    // Implement deletion logic here
    // Close the modal and update state as needed
    setSelectedAgentRuntime(null);
    deleteAgentRuntime(runtimeId, token);
  };

  useEffect(() => {
    if (token !== undefined) {
      listDesktopRuntimes(token).then((data) => setProviders(data));
      // Then set the interval
      const intervalId = setInterval(async () => {
        var providers = await listDesktopRuntimes(token);
        console.log("setting providers: ");
        console.log(providers);
        setProviders(providers);
      }, 2000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [token]);

  useEffect(() => {
    if (token !== undefined) {
      listAgentRuntimes(token).then((data) => setAgentRuntimes(data));
      // Then set the interval
      const intervalId = setInterval(async () => {
        var providers = await listAgentRuntimes(token);
        console.log("setting providers: ");
        console.log(providers);
        setAgentRuntimes(providers);
      }, 2000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [token]);

  useEffect(() => {
    console.log("providers:");
    console.log(providers);
  }, [providers]);

  return (
    <>
      <Dialog open={openRegister} handler={handleOpenRegister}>
        <DialogHeader>
          <PencilSquareIcon className="h-5 w-5 mr-4" />
          Register Desktop Runtime
        </DialogHeader>
        <DialogBody>
          <RegisterDesktopRuntimeModal onClose={handleCloseRegister} />
          {/* <Typography className="text-2xl font-bold mb-4">Enter the URL of the agent</Typography>
                  <Input className="" label="Agent URL" type="text"/> */}
        </DialogBody>
        <DialogFooter></DialogFooter>
      </Dialog>
      <Dialog open={openAgentRegister} handler={handleOpenAgentRegister}>
        <DialogHeader>
          <PencilSquareIcon className="h-5 w-5 mr-4" />
          Register Agent Runtime
        </DialogHeader>
        <DialogBody>
          <RegisterAgentRuntimeModal onClose={handleCloseAgentRegister} />
          {/* <Typography className="text-2xl font-bold mb-4">Enter the URL of the agent</Typography>
                  <Input className="" label="Agent URL" type="text"/> */}
        </DialogBody>
        <DialogFooter></DialogFooter>
      </Dialog>
      <Layout>
        <div className="flex flex-col mt-10">
          <div className="ml-48">{/* <AgentsSidebar /> */}</div>
          <div className="flex flex-col rounded-lg">
            <div className="pl-48 flex flex-row gap-6 items-center">
              <Typography className="text-2xl font-bold">
                Desktop Runtimes
              </Typography>
              <Typography className="text-2xl font-bold text-gray-700">
                {providers?.length}
              </Typography>
            </div>
            <div className="pl-56 flex flex-wrap p-16 gap-10">
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                whileHover={{ scale: 1.05 }}
                className="mt-8 mr-8 mb-8"
              >
                <PlusButtonText text="Register" onClick={handleOpenRegister} />
              </motion.div>
              {providers &&
                providers.map((providerObj, key) => (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                    whileHover={{ scale: 1.05 }}
                  >
                    <Card
                      className="mt-6 mb-8 w-64 h-48 cursor-pointer"
                      key={key}
                      onClick={() => handleDesktopRuntimeClick(providerObj)}
                    >
                      <CardHeader
                        color="blue-gray"
                        className="relative h-56 bg-white"
                      >
                        <img
                          className="w-64 h-36 object-cover"
                          src={getImageUrlForProviderType(providerObj.provider)}
                          alt={providerObj.name}
                        />
                      </CardHeader>
                      <CardBody>
                        <Typography
                          variant="h5"
                          color="blue-gray"
                          className="mb-2"
                        >
                          {providerObj.name}
                        </Typography>
                      </CardBody>
                    </Card>
                  </motion.div>
                ))}
            </div>
          </div>
          <div className="flex flex-col border-black">
            <div className="pl-48 flex flex-row gap-6 items-center">
              <Typography className="text-2xl font-bold">
                Agent Runtimes
              </Typography>
              <Typography className="text-2xl font-bold text-gray-700">
                {agentRuntimes?.length}
              </Typography>
            </div>
            <div className="pl-56 flex flex-wrap p-16 gap-10">
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                whileHover={{ scale: 1.05 }}
                className="mt-8 mr-8 mb-8"
              >
                <PlusButtonText
                  text="Register"
                  onClick={handleOpenAgentRegister}
                />
              </motion.div>
              {agentRuntimes &&
                agentRuntimes.map((providerObj, key) => (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                    whileHover={{ scale: 1.05 }}
                  >
                    <Card
                      className="mt-6 mb-8 w-64 h-48 cursor-pointer"
                      key={key}
                      onClick={() => handleAgentRuntimeClick(providerObj)}
                    >
                      <CardHeader color="blue-gray" className="relative h-56">
                        <img
                          className="w-64 h-36 object-cover bg-white"
                          src={getImageUrlForProviderType(providerObj.provider)}
                          alt={providerObj.name}
                        />
                      </CardHeader>
                      <CardBody>
                        <Typography
                          variant="h5"
                          color="blue-gray"
                          className="mb-2"
                        >
                          {providerObj.name}
                        </Typography>
                      </CardBody>
                    </Card>
                  </motion.div>
                ))}
            </div>
          </div>
        </div>
      </Layout>
      {selectedDesktopRuntime && (
        <DesktopRuntimeModal
          runtime={selectedDesktopRuntime}
          onClose={() => setSelectedDesktopRuntime(null)}
          onDelete={deleteDesktopRuntimeFunc}
        />
      )}

      {selectedAgentRuntime && (
        <AgentRuntimeModal
          runtime={selectedAgentRuntime}
          onClose={() => setSelectedAgentRuntime(null)}
          onDelete={deleteAgentRuntimeFunc}
        />
      )}
    </>
  );
}
