import React, { useState, useEffect } from "react";
import { Typography } from "@material-tailwind/react";
import RoleThread from "./RoleThread";

const RoleThreads = ({ threads = [] }) => {
  const [selectedThreadName, setSelectedThreadName] = useState(
    threads[0]?.name || ""
  );

  const getSelectedThread = () => {
    return threads.find((thread) => thread.name === selectedThreadName);
  };

  useEffect(() => {
    const scrollContainer = document.querySelector(".scroll-container");
    if (scrollContainer) {
      scrollContainer.scrollTo({
        top: scrollContainer.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [selectedThreadName]);

  return (
    <div className="flex flex-row mt-4">
      <div className="flex flex-col gap-4 justify-start mb-2 mx-2">
        {threads.map((thread) => (
          <Typography
            variant="paragraph"
            className={`font-sm mr-2 p-2 rounded-md cursor-pointer ${
              selectedThreadName === thread.name
                ? "bg-blue-gray-50"
                : "hover:bg-blue-gray-50"
            }`}
            key={thread.name}
            onClick={() => setSelectedThreadName(thread.name)}
          >
            #{thread.name}
          </Typography>
        ))}
      </div>
      <div className="overflow-y-scroll max-h-[580px] w-full scroll-container">
        <RoleThread
          data={getSelectedThread()}
          isExpandable={false}
          expanded={true}
        />
      </div>
    </div>
  );
};

export default RoleThreads;
