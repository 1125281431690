import { UnauthorizedError } from "./Error";
import { getAPIAddr } from "./Env";

export async function listDesktopRuntimes(token) {
  const url = new URL("/v1/runtimes/desktops", getAPIAddr());
  console.log("listing runtimes from URL: ", url);

  console.log("calling runtimes with token: ", token);

  try {
    const resp = await fetch(url, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      redirect: "follow",
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log(resp);
    const data = await resp.json();
    console.log("got list desktop runtime data: ", data);
    var runtimes = data.runtimes;

    return runtimes;
  } catch (error) {
    console.error("Failed to fetch data", error);
  }
}

export async function registerDesktopRuntime(
  name,
  provider,
  credentials,
  sharedWith,
  token
) {
  const url = new URL("/v1/runtimes/desktops", getAPIAddr());
  console.log("registering runtime provider from URL: ", url);

  console.log("calling runtime provider with token: ", token);

  var bodyData = {
    name: name,
    provider: provider,
    credentials: credentials,
    shared_with: sharedWith,
  };
  try {
    const resp = await fetch(url, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      redirect: "follow",
      body: JSON.stringify(bodyData),
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log(resp);
    const data = await resp.json();
    console.log("got registered provider: ", data);

    return data;
  } catch (error) {
    console.error("Failed to fetch data", error);
  }
}

export async function listAgentRuntimes(token) {
  const url = new URL("/v1/runtimes/agents", getAPIAddr());
  console.log("listing runtimes from URL: ", url);

  console.log("calling runtimes with token: ", token);

  try {
    const resp = await fetch(url, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      redirect: "follow",
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log(resp);
    // const text = await resp.text();
    // console.log(text);
    const data = await resp.json();
    console.log("got list agent runtimes data: ", data);
    var runtimes = data.runtimes;

    return runtimes;
  } catch (error) {
    console.error("Failed to fetch data", error);
  }
}

export async function registerAgentRuntime(
  name,
  provider,
  credentials,
  meta,
  sharedWith,
  token
) {
  const url = new URL("/v1/runtimes/agents", getAPIAddr());
  console.log("registering agent runtime provider from URL: ", url);
  console.log("registering agent runtime provider from metadata: ", meta);
  console.log(
    "registering agent runtime provider from sharedWith: ",
    sharedWith
  );
  console.log("calling agent runtime provider with token: ", token);
  console.log("calling agent runtime provider with provider: ", provider);

  var bodyData = {
    name: name,
    provider: provider,
    credentials: credentials,
    metadata: meta,
    shared_with: sharedWith,
  };
  try {
    const resp = await fetch(url, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      redirect: "follow",
      body: JSON.stringify(bodyData),
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log(resp);
    const data = await resp.json();
    console.log("got registered provider: ", data);

    return data;
  } catch (error) {
    console.error("Failed to fetch data", error);
  }
}

export async function deleteDesktopRuntime(name, token) {
  const url = new URL(`/v1/runtimes/desktops/${name}`, getAPIAddr());
  console.log("deleting desktop runtime with name: ", name);

  try {
    const resp = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log("Desktop runtime deleted successfully: ", name);
    return await resp.json(); // Assuming the response includes a JSON body
  } catch (error) {
    console.error("Failed to delete desktop runtime", error);
  }
}

export async function deleteAgentRuntime(name, token) {
  const url = new URL(`/v1/runtimes/agents/${name}`, getAPIAddr());
  console.log("deleting agent runtime with name: ", name);

  try {
    const resp = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log("Agent runtime deleted successfully: ", name);
    return await resp.json(); // Assuming the response includes a JSON body
  } catch (error) {
    console.error("Failed to delete agent runtime", error);
  }
}
