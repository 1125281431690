import {
    Card,
    Typography,
    Rating,
    Chip,
  } from "@material-tailwind/react";
  import {
    StarIcon,
    TagIcon,
  } from "@heroicons/react/24/solid";
   
  export function AgentsSidebar() {
    return (
      <Card className="h-[calc(100vh-2rem)] w-full min-w-[15rem] max-w-[20rem] p-4 shadow-2xl rounded-3xl shadow-blue-gray-900/5">
        <div className="mb-2 p-4">
          <Typography variant="h5" color="blue-gray">
            Filter
          </Typography>
        </div>
        <div className="flex flex-row gap-2 mb-4">
              <TagIcon className="h-5 w-5" />
            Tags
        </div>
        <div className="flex flex-wrap gap-2 mb-4 max-w-[15rem]">
          <Chip color="blue" value="HR" className="cursor-pointer" variant="outlined"/>
          <Chip color="green" value="coding" className="cursor-pointer" variant="outlined" />
          <Chip color="purple" value="writing" className="cursor-pointer" variant="outlined"/>
          <Chip color="cyan" value="ranking" className="cursor-pointer" variant="outlined"/>
          <Chip color="indigo" value="vision" className="cursor-pointer" variant="outlined"/>
        </div>
        <div className="flex flex-row gap-2 mb-4">
              <StarIcon className="h-5 w-5" />
            Rating
        </div>
        <div className="">
          <Rating />
        </div>
      </Card>
    );
  }