import { UnauthorizedError } from "./Error";
import { getAPIAddr } from "./Env";

export async function createTask(taskData, token) {
  const url = new URL("/v1/tasks", getAPIAddr());
  console.log("Creating task at URL: ", url);

  try {
    const resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(taskData),
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    const task = await resp.json();
    console.log("Created task: ", task);
    return task;
  } catch (error) {
    console.error("Failed to create task", error);
  }
}

export async function getTasks(token) {
  const url = new URL("/v1/tasks", getAPIAddr());
  console.log("Fetching tasks from URL: ", url);

  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    const data = await resp.json();
    console.log("Fetched tasks: ", data.tasks);
    return data.tasks;
  } catch (error) {
    console.error("Failed to fetch tasks", error);
  }
}

export async function getTask(taskId, token) {
  const url = new URL(`/v1/tasks/${taskId}`, getAPIAddr());
  console.log("Fetching task from URL: ", url);

  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    const task = await resp.json();
    console.log("Fetched task: ", task);
    return task;
  } catch (error) {
    console.error("Failed to fetch task", error);
  }
}

export async function deleteTask(taskId, token) {
  const url = new URL(`/v1/tasks/${taskId}`, getAPIAddr());
  console.log("Deleting task at URL: ", url);

  try {
    const resp = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    console.log("Task deleted successfully");
  } catch (error) {
    console.error("Failed to delete task", error);
  }
}

export async function updateTask(taskId, updateData, token) {
  const url = new URL(`/v1/tasks/${taskId}`, getAPIAddr());
  console.log("Updating task at URL: ", url);

  try {
    const resp = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updateData),
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    const task = await resp.json();
    console.log("Updated task: ", task);
    return task;
  } catch (error) {
    console.error("Failed to update task", error);
  }
}

export async function postTaskMessage(taskId, messageData, token) {
  const url = new URL(`/v1/tasks/${taskId}/msg`, getAPIAddr());
  console.log("posting message to task with URL: ", url);

  try {
    const resp = await fetch(url, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(messageData),
      redirect: "follow",
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    const data = await resp.json();
    console.log("posted message to task: ", data);
    return data;
  } catch (error) {
    console.error("Failed to post message to task", error);
  }
}

export async function getPrompts(taskId, token) {
  const url = new URL(`/v1/tasks/${taskId}/prompts`, getAPIAddr());
  console.log("Fetching task prompts from URL: ", url);

  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
    const data = await resp.json();
    console.log("Fetched prompts: ", data.prompts);
    return data.prompts;
  } catch (error) {
    console.error("Failed to fetch tasks", error);
  }
}

export async function approvePrompt(taskId, promptId, token) {
  const url = new URL(
    `/v1/tasks/${taskId}/prompts/${promptId}/approve`,
    getAPIAddr()
  );
  console.log("Approving prompt from URL: ", url);

  try {
    const resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
  } catch (error) {
    console.error("Failed to approve prompt", error);
  }
}

export async function failPrompt(taskId, promptId, token) {
  const url = new URL(
    `/v1/tasks/${taskId}/prompts/${promptId}/fail`,
    getAPIAddr()
  );
  console.log("Failing prompt from URL: ", url);

  try {
    const resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (resp.status === 401) {
      throw new UnauthorizedError("User is unauthorized");
    }
    if (!resp.ok) {
      throw new Error("HTTP status " + resp.status);
    }
  } catch (error) {
    console.error("Failed to fail prompt", error);
  }
}
