export function getAuthAPIAddr() {
  const addr = process.env.REACT_APP_AUTH_API_ADDR;
  return addr;
}

export function getAPIAddr() {
  const addr = process.env.REACT_APP_API_ADDR;
  return addr;
}

export function isProd() {
  const env = process.env.REACT_APP_ENV;
  if (env === "prod") {
    return true;
  }
  return false;
}
